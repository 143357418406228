import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    equityScrollingTicker: {},
    equityDashboardDetail: {},
    equity_dash_anon_data: {},
    arrForColorScale: {},
    watchArrForColorScale: {},
    loader: false,
    watchlist: [],
    newDataFetched: false,
    chartdata: [],
    tickers: [],
    marketMoverData: {}
};

export const EquityDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_MARKET_MOVER_INIT:
        case ActionTypes.FETCH_EQUITY_DASHBOARD_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_SCROLLING_TICKER_INIT:
        case ActionTypes.SAVE_WATCHLIST_INIT:
        case ActionTypes.DELETE_WATCHLIST_INIT:
        case ActionTypes.UPDATE_WATCHLIST_INIT:
        case ActionTypes.FETCH_WATCHLIST_CHART_INIT:
        case ActionTypes.FETCH_WATCHLIST_COMPANY_NAME_INIT:
        case ActionTypes.FETCH_TICKER_LIST_INIT:
        case ActionTypes.FETCH_WATCHLIST_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_EQUITY_DASHBOARD_SUCCESS:
            const data = [];
            let arrColorField = {};
            const colorScaleFields = ['Daily Price Change %', '5 Day Change%', '1 Year Change%'];
            action.data.data.map((val, i) => {
                let rowData = {};
                val.label.map((subval, subI) => {
                    if (colorScaleFields.indexOf(subval) > -1) {
                        if (arrColorField[subval]) {
                            arrColorField[subval].push(val.value[subI]);
                        } else {
                            arrColorField = { ...arrColorField, [subval]: [] };
                            arrColorField[subval].push(val.value[subI]);
                        }
                    }
                    rowData = { ...rowData, [subval]: val.value[subI] };
                    return rowData;
                });
                return data.push(rowData);
            });
            return {
                ...state,
                loader: false,
                equityDashboardDetail: { data },
                arrForColorScale: arrColorField
            };
        case ActionTypes.FETCH_SCROLLING_TICKER_SUCCESS:
            const _data = [];
            action.data.map((val, i) => {
                let rowData = {};
                val.label.map((subval, subI) => {
                    rowData = { ...rowData, [subval]: val.value[subI] };
                    return rowData;
                });
                return _data.push(rowData);
            });
            return {
                ...state,
                loader: false,
                equityScrollingTicker: { _data }
            };
        case ActionTypes.FETCH_EQUITY_DASHBOARD_ERROR:
        case ActionTypes.FETCH_SCROLLING_TICKER_ERROR:
        case ActionTypes.FETCH_WATCHLIST_ERROR:
            return {
                ...state,
                watchlist: [],  // Fallback to empty array
                loader: false
            };
        case ActionTypes.FETCH_WATCHLIST_CHART_ERROR:
        case ActionTypes.FETCH_WATCHLIST_COMPANY_NAME_ERROR:
        case ActionTypes.FETCH_TICKER_LIST_ERROR:
        case ActionTypes.SAVE_WATCHLIST_SUCCESS:
            if (action.txt === 'subwatchlist') {
                const newItem = action.data;
                const updatedWatchlist = state.watchlist.map((watchlistItem) => {
                    if (watchlistItem.id === newItem.watchlist_id) {
                        return {
                            ...watchlistItem,
                            subwatchlist: Array.isArray(watchlistItem.subwatchlist) ? [...watchlistItem.subwatchlist, newItem] : [newItem]
                        };
                    }
                    return watchlistItem;
                });

                return {
                    ...state,
                    loader: false,
                    watchlist: updatedWatchlist,
                    newDataFetched: true
                };
            } else {
                const newWatchlist = action.data;
                return {
                    ...state,
                    loader: false,
                    watchlist: [...state.watchlist, newWatchlist],
                    newDataFetched: true
                };
            }

        case ActionTypes.UPDATE_WATCHLIST_SUCCESS:
            const updatedItem = action.data;
            const updatedWatchlistAfterUpdate = state.watchlist.map((watchlistItem) => {
                if (watchlistItem.id === updatedItem.watchlist_id) {
                    return {
                        ...watchlistItem,
                        name: updatedItem.name
                    };
                }
                return watchlistItem;
            });

            return {
                ...state,
                loader: false,
                watchlist: updatedWatchlistAfterUpdate,
                newDataFetched: true
            };

        case ActionTypes.DELETE_WATCHLIST_SUCCESS:
            if (action.txt === 'subwatchlist') {
                const { watchlist_id, sub_watchlist_id } = action.data;
                const updatedWatchlistAfterDelete = state.watchlist.map((watchlistItem) => {
                    if (watchlistItem.id === watchlist_id) {
                        return {
                            ...watchlistItem,
                            subwatchlist: watchlistItem.subwatchlist.filter((item) => item.id !== sub_watchlist_id)
                        };
                    }
                    return watchlistItem;
                });

                return {
                    ...state,
                    loader: false,
                    watchlist: updatedWatchlistAfterDelete,
                    newDataFetched: true
                };
            } else {
                const { id } = action.data;
                const updatedWatchlistAfterDelete = state.watchlist.filter((watchlistItem) => watchlistItem.id !== id);

                return {
                    ...state,
                    loader: false,
                    watchlist: updatedWatchlistAfterDelete,
                    newDataFetched: true
                };
            }
        case ActionTypes.FETCH_MARKET_MOVER_SUCCESS:
            return { ...state, marketMoverData: action.data, loader: false };
        case ActionTypes.FETCH_MARKET_MOVER_ERROR:
                return { ...state, loader: false };
        case ActionTypes.FETCH_WATCHLIST_SUCCESS:
            return {
                ...state,
                watchlist: action.data || [], // Fallback to empty array if action.data is undefined
                loader: false
            };
        case ActionTypes.FETCH_WATCHLIST_COMPANY_NAME_SUCCESS:
            return { ...state, equity_dash_anon_data: action.data, loader: false };
        case ActionTypes.FETCH_TICKER_LIST_SUCCESS:
            return { ...state, tickers: action.source === 'scroll' ? [...state.tickers, ...action.data] : [...action.data] };
        case ActionTypes.HANDLE_DATA_FETCH:
            return { ...state, newDataFetched: action.data, loader: false };
        case ActionTypes.FETCH_WATCHLIST_CHART_SUCCESS:
            return { ...state, chartdata: action.data, loader: false };
        default:
            return state;
    }
};
