import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Typography } from '@mui/material';
import { CalBannerBox, CalBreadcrumbs, TitleBox } from 'assets/jss/GeneralStyle';
import { HashLink as Link } from 'react-router-hash-link';
import { useSelector } from 'react-redux';

const varientTextArr = [
    'Credit Card Calculator',
    'Debt Calculator',
    'College Score Calculator',
    'Portfolio Valuation',
    'Fixed Income Dashboard'
];

const VTArr = ['News & Research', 'Finance Todo', 'News Feed', 'Blogs and Research'];

const CommonCalculator = (props) => {
    const { breadcrumbs, variantText, subVarientText } = props;
    const setting = useSelector((state) => state.setting);

    const scrollToDescription = () => {
        const descriptionSection = document.getElementById('description');
        descriptionSection.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <CalBannerBox img={'/img/retirement-bg.png'} customheight={'130px'} paddingvalue={props.paddingvalue}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <TitleBox>
                    <CalBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="calculator breadcrumb">
                        {breadcrumbs}
                    </CalBreadcrumbs>
                    <Box>
                        <Typography variant={'h1'} className="cal-title">
                            {variantText}
                        </Typography>
                        {!VTArr.includes(variantText) && (
                            <button
                                style={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    backgroundColor: 'transparent',
                                    color: '#fff',
                                    textDecoration: 'none',
                                    borderRadius: '5px',
                                    border: '1px solid white',
                                    cursor: 'pointer',
                                    fontSize: '18px',
                                    marginBottom: variantText === 'Portfolio Analysis' ? '10px' : ''
                                }}
                                onClick={scrollToDescription}
                            >
                                Description
                            </button>
                        )}
                        <Typography variant="subheading1">{subVarientText}</Typography>
                    </Box>
                </TitleBox>
                {varientTextArr.includes(variantText) && setting.profileDetail.role === 'admin' && (
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            border: '1px solid white',
                            color: 'white',
                            padding: '10px',
                            cursor: 'pointer',
                            marginRight: '25px',
                            fontSize: '18px',
                            borderRadius: '10px'
                        }}
                        onClick={props?.handleTour}
                    >
                        Take a tour
                    </button>
                )}
            </div>
        </CalBannerBox>
    );
};
export default CommonCalculator;
