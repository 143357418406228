import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    budgetCalData: {},
    budgetFileList: [],
    selectedBudgetDetail: {},
    archivedBudgetDetail: {},
    submitSmartTblSuccess: false,
    loader: false,
    savedBudgetData: [],
    taxEstiData: {},
    savedTaxEstiData: {},
    plaidBankData: {}
};
export const BudgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SUBMIT_BUDGET_CAL_INIT:
        case ActionTypes.UPLOAD_BUDGET_FILE_INIT:
        case ActionTypes.GET_BUDGET_FILE_INIT:
        case ActionTypes.DELETE_BUDGET_FILE_INIT:
        case ActionTypes.FETCH_SAVED_BUDGET_INIT:
        case ActionTypes.DELETE_SAVED_BUDGET_INIT:
        case ActionTypes.SUBMIT_TAX_ESTI_INIT:
        case ActionTypes.SAVE_TAX_ESTI_INIT:
        case ActionTypes.FETCH_SAVED_TAX_ESTI_INIT:
        case ActionTypes.FETCH_SELECTED_BUDGET_FILE_INIT:
        case ActionTypes.FETCH_ARCHIVED_BUDGET_INIT:
        case ActionTypes.SMART_BUDGET_INIT:
        case ActionTypes.GET_PLAID_BANK_DETAILS_INIT:
            return { ...state, loader: true };

        case ActionTypes.SUBMIT_BUDGET_CAL_ERROR:
        case ActionTypes.DELETE_SAVED_BUDGET_ERROR:
        case ActionTypes.DELETE_SAVED_BUDGET_SUCCESS:
        case ActionTypes.FETCH_SAVED_BUDGET_ERROR:
        case ActionTypes.UPLOAD_BUDGET_FILE_ERROR:
        case ActionTypes.GET_BUDGET_FILE_ERROR:
        case ActionTypes.UPLOAD_BUDGET_FILE_SUCCESS:
        case ActionTypes.DELETE_BUDGET_FILE_ERROR:
        case ActionTypes.FETCH_SELECTED_BUDGET_FILE_ERROR:
        case ActionTypes.FETCH_ARCHIVED_BUDGET_ERROR:
        case ActionTypes.SUBMIT_TAX_ESTI_ERROR:
        case ActionTypes.SAVE_TAX_ESTI_ERROR:
        case ActionTypes.FETCH_SAVED_TAX_ESTI_ERROR:
        case ActionTypes.DELETE_BUDGET_FILE_SUCCESS:
        case ActionTypes.GET_PLAID_BANK_DETAILS_ERROR:
            return { ...state, loader: false };

        case ActionTypes.SUBMIT_BUDGET_CAL_SUCCESS:
            return { ...state, loader: false, budgetCalData: action.data };
        case ActionTypes.GET_BUDGET_FILE_SUCCESS:
            return { ...state, loader: false, budgetFileList: action.data };
        case ActionTypes.FETCH_SELECTED_BUDGET_FILE_SUCCESS:
            return { ...state, loader: false, selectedBudgetDetail: action.data };
        case ActionTypes.FETCH_ARCHIVED_BUDGET_SUCCESS:
            return { ...state, loader: false, archivedBudgetDetail: action.data };
        case ActionTypes.FETCH_SAVED_BUDGET_SUCCESS:
            return { ...state, loader: false, savedBudgetData: action.data };
        case ActionTypes.SMART_BUDGET_SUCCESS:
            return { ...state, loader: false, submitSmartTblSuccess: true };
        case ActionTypes.SMART_BUDGET_ERROR:
            return { ...state, loader: false, submitSmartTblSuccess: false };
        case ActionTypes.SUBMIT_TAX_ESTI_SUCCESS:
            return { ...state, loader: false, taxEstiData: action.data };
        case ActionTypes.FETCH_SAVED_TAX_ESTI_SUCCESS:
            return {...state, loader: false, savedTaxEstiData: action.data};
        case ActionTypes.SAVE_TAX_ESTI_SUCCESS:
            return {...state, loader: false, taxEstiData: action.data};
        case ActionTypes.GET_PLAID_BANK_DETAILS_SUCCESS:
            return { ...state, loader: false, plaidBankData: action.data };
        default:
            return state;
    }
};
