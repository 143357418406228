import * as ActionTypes from '../actions/ActionTypes';
import {
    FETCH_CHAT_MESSAGES_ERROR,
    FETCH_CHAT_MESSAGES_SUCCESS,
    UPDATE_CHAT_MESSAGES_ERROR,
    UPDATE_CHAT_MESSAGES_SUCCESS
} from '../actions/ActionTypes';

const initialState = {
    activeSettingsTab: 0,
    activeSettingsAdminPanelTab: 0,
    profileDetail: {},
    loader: false,
    trafficlightData: [],
    trafficDialogVisiblity: false,
    moduleList: [],
    newsList: [],
    subscriptionList: [],
    subscriptionFormDialog: false,
    newsFeedFormDialog: false,
    moduleFormDialog: false,
    equityTickerData: [],
    equityTickerFormDialog: false,
    economyTickerData: [],
    portfolioAllocationData: [],
    ecoDashboardOrder: [],
    economyTickerFormDialog: false,
    portfolioAllocationFormDialog: false,
    redirectSubPage: false,
    billingDetails: {},
    invoiceList: [],
    feedbackList: [],
    userWidCalList: [],
    newsArticle: [],
    widgetCalList: [],
    cardDetails: [],
    widgetDialogFlag: false,
    plaidDetail: {
        plaid_token_detail: {},
        user_detail: []
    },
    chatMessages: [],
    validatedChatMessages: [],
    latestNewsList: []
};

export const SettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_PROFILE_SUCCESS:
            return { ...state, loader: false, profileDetail: action.data };
        case ActionTypes.SUBMIT_PROFILE_ERROR:
        case ActionTypes.UPDATE_PAYMENT_ERROR:
        case ActionTypes.GET_CARD_ERROR:
        case ActionTypes.SUBMIT_PROFILE_SETTINGS_ERROR:
        case ActionTypes.SUBMIT_PROFILE_SUCCESS:
        case ActionTypes.UPDATE_PAYMENT_SUCCESS:
        case ActionTypes.UPDATE_CHAT_MESSAGES_SUCCESS:
        case ActionTypes.UPDATE_CHAT_VALIDATE_MESSAGES_SUCCESS:
        case ActionTypes.SUBMIT_TRAFFIC_LIGHT_SUCCESS:
        case ActionTypes.SUBMIT_PROFILE_SETTINGS_SUCCESS:
        case ActionTypes.SUBMIT_BILLING_UPDATE_SUCCESS:
        case ActionTypes.SUBMIT_WIDGET_CAL_SUCCESS:
        case ActionTypes.CONNECT_PLAID_SUCCESS:
        case ActionTypes.SUBMIT_WIDGET_CAL_ERROR:
        case ActionTypes.SUBMIT_WIDGET_USER_CAL_SUCCESS:
        case ActionTypes.SUBMIT_WIDGET_USER_CAL_ERROR:
        case ActionTypes.FETCH_PROFILE_ERROR:
        case ActionTypes.SUBMIT_TRAFFIC_LIGHT_ERROR:
        case ActionTypes.FETCH_TRAFFIC_LIGHT_ERROR:
        case ActionTypes.SUBMIT_TPE_MODULE_ERROR:
        case ActionTypes.FETCH_TPE_MODULE_ERROR:
        case ActionTypes.FETCH_EQUITY_TICKER_ERROR:
        case ActionTypes.SUBMIT_SUBSCRIPTION_ERROR:
        case ActionTypes.SUBMIT_NEWS_FEED_ERROR:
        case ActionTypes.FETCH_SUBSCRIPTION_ERROR:
        case ActionTypes.FETCH_NEWS_FEED_ERROR:
        case ActionTypes.FETCH_LATEST_NEWS_ERROR:
        case ActionTypes.SUBMIT_EQUITY_TICKER_ERROR:
        case ActionTypes.FETCH_INVOICE_HISTORY_ERROR:
        case ActionTypes.FETCH_FEEDBACK_ERROR:
        case ActionTypes.FETCH_VALIDATED_CHAT_MESSAGES_ERROR:
        case ActionTypes.UPDATE_CHAT_MESSAGES_ERROR:
        case ActionTypes.UPDATE_CHAT_VALIDATE_MESSAGES_ERROR:
        case ActionTypes.FETCH_NEWS_BY_ID_ERROR:
        case ActionTypes.FETCH_BILLING_DETAILS_ERROR:
        case ActionTypes.SUBMIT_BILLING_UPDATE_ERROR:
        case ActionTypes.GENERATE_PLAID_TOKEN_ERROR:
        case ActionTypes.FETCH_WIDGET_CAL_LIST_ERROR:
        case ActionTypes.GET_CONNECTED_ACC_DETAIL_ERROR:
        case ActionTypes.CONNECT_PLAID_ERROR:
        case ActionTypes.FETCH_WIDGET_USER_CAL_LIST_ERROR:
            return { ...state, loader: false };
        case ActionTypes.SUBMIT_PROFILE_INIT:
        case ActionTypes.UPDATE_PAYMENT_INIT:
        case ActionTypes.GET_CARD_INIT:
        case ActionTypes.SUBMIT_PROFILE_SETTINGS_INIT:
        case ActionTypes.FETCH_PROFILE_INIT:
        case ActionTypes.SUBMIT_TRAFFIC_LIGHT_INIT:
        case ActionTypes.FETCH_TRAFFIC_LIGHT_INIT:
        case ActionTypes.SUBMIT_TPE_MODULE_INIT:
        case ActionTypes.FETCH_TPE_MODULE_INIT:
        case ActionTypes.SUBMIT_SUBSCRIPTION_INIT:
        case ActionTypes.SUBMIT_NEWS_FEED_INIT:
        case ActionTypes.FETCH_NEWS_BY_ID_INIT:
        case ActionTypes.FETCH_SUBSCRIPTION_INIT:
        case ActionTypes.FETCH_NEWS_FEED_INIT:
        case ActionTypes.FETCH_LATEST_NEWS_INIT:
        case ActionTypes.SUBMIT_EQUITY_TICKER_INIT:
        case ActionTypes.FETCH_EQUITY_TICKER_INIT:
        case ActionTypes.FETCH_BILLING_DETAILS_INIT:
        case ActionTypes.FETCH_INVOICE_HISTORY_INIT:
        case ActionTypes.SUBMIT_BILLING_UPDATE_INIT:
        case ActionTypes.SUBMIT_WIDGET_CAL_INIT:
        case ActionTypes.SUBMIT_WIDGET_USER_CAL_INIT:
        case ActionTypes.GENERATE_PLAID_TOKEN_INIT:
        case ActionTypes.CONNECT_PLAID_INIT:
        case ActionTypes.FETCH_CHAT_MESSAGES_INIT:
        case ActionTypes.UPDATE_CHAT_MESSAGES_INIT:
        case ActionTypes.UPDATE_CHAT_VALIDATE_MESSAGES_INIT:
        case ActionTypes.FETCH_VALIDATED_CHAT_MESSAGES_INIT:
        case ActionTypes.FETCH_FEEDBACK_INIT:
        case ActionTypes.FETCH_WIDGET_USER_CAL_LIST_INIT:
        case ActionTypes.FETCH_WIDGET_CAL_LIST_INIT:
        case ActionTypes.GET_CONNECTED_ACC_DETAIL_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_CHAT_MESSAGES_SUCCESS:
            return { ...state, loader: false, chatMessages: action.data };
        case ActionTypes.FETCH_VALIDATED_CHAT_MESSAGES_SUCCESS:
            return { ...state, loader: false, validatedChatMessages: action.data };
        case ActionTypes.FETCH_TRAFFIC_LIGHT_SUCCESS:
            return { ...state, loader: false, trafficlightData: action.data };
        case ActionTypes.HANDLE_DIALOG_VISIBLITY:
            return { ...state, trafficDialogVisiblity: action.data };
        case ActionTypes.SUBMIT_TPE_MODULE_SUCCESS:
            return { ...state, loader: false, moduleFormDialog: action.data };
        case ActionTypes.FETCH_TPE_MODULE_SUCCESS:
            return { ...state, loader: false, moduleList: action.data };
        case ActionTypes.FETCH_NEWS_FEED_SUCCESS:
            return { ...state, loader: false, newsList: action.data };
        case ActionTypes.FETCH_LATEST_NEWS_SUCCESS:
                return { ...state, loader: false, latestNewsList: action.data };
        case ActionTypes.FETCH_SUBSCRIPTION_SUCCESS:
            const sortData = action.data.sort((a, b) => (a.level > b.level ? 1 : -1));
            return { ...state, loader: false, subscriptionList: sortData };
        case ActionTypes.SUBMIT_SUBSCRIPTION_SUCCESS:
            return { ...state, loader: false, subscriptionFormDialog: action.data };
        case ActionTypes.SUBMIT_NEWS_FEED_SUCCESS:
            return { ...state, loader: false, newsFeedFormDialog: action.data };
        case ActionTypes.SUBMIT_EQUITY_TICKER_SUCCESS:
            return { ...state, loader: false, equityTickerFormDialog: action.data };
        case ActionTypes.FETCH_EQUITY_TICKER_SUCCESS:
            return { ...state, loader: false, equityTickerData: action.data };
        case ActionTypes.SUBMIT_ECONOMIC_TICKER_SUCCESS:
            return { ...state, loader: false, economyTickerFormDialog: action.data };
        case ActionTypes.SUBMIT_PORTFOLIO_ALLOCATION_SUCCESS:
            return { ...state, loader: false, portfolioAllocationFormDialog: action.data };
        case ActionTypes.FETCH_ECONOMIC_TICKER_SUCCESS:
            return { ...state, loader: false, economyTickerData: action.data };
        case ActionTypes.FETCH_PORTFOLIO_ALLOCATION_SUCCESS:
            return { ...state, loader: false, portfolioAllocationData: action.data };
        case ActionTypes.UPDATE_ECONOMIC_DASHBOARD_SUCCESS:
            return { ...state, loader: false, ecoDashboardOrder: action.data };
        case ActionTypes.FETCH_BILLING_DETAILS_SUCCESS:
            return { ...state, loader: false, billingDetails: action.data };
        case ActionTypes.FETCH_INVOICE_HISTORY_SUCCESS:
            return { ...state, loader: false, invoiceList: action.data };
        case ActionTypes.FETCH_FEEDBACK_SUCCESS:
            return { ...state, loader: false, feedbackList: action.data };
        case ActionTypes.FETCH_WIDGET_CAL_LIST_SUCCESS:
            return { ...state, loader: false, widgetCalList: action.data };
        case ActionTypes.FETCH_WIDGET_USER_CAL_LIST_SUCCESS:
            return { ...state, loader: false, userWidCalList: action.data };
        case ActionTypes.LOAD_SUB_SETTING_PAGE:
            return { ...state, redirectSubPage: action.data };
        case ActionTypes.SUBMIT_WIDGET_DIALOG_SUCCESS:
            return { ...state, loader: false, widgetDialogFlag: action.data };
        case ActionTypes.GENERATE_PLAID_TOKEN_SUCCESS:
            return { ...state, loader: false, plaidDetail: { ...state.plaidDetail, plaid_token_detail: action.data } };
        case ActionTypes.GET_CONNECTED_ACC_DETAIL_SUCCESS:
            return { ...state, loader: false, plaidDetail: { ...state.plaidDetail, user_detail: action.data } };
        case ActionTypes.GET_CARD_SUCCESS:
            return { ...state, loader: false, cardDetails: action.data };
        case ActionTypes.FETCH_NEWS_BY_ID_SUCCESS:
            return { ...state, newsArticle: action.data, loader: false };

        case ActionTypes.DELETE_CONNECTED_ACC_DETAIL_SUCCESS:
            return {
                ...state,
                loader: false,
                plaidDetail: { ...state.plaidDetail, user_detail: state.plaidDetail.user_detail.filter((obj) => obj.id !== action.data) }
            };
        case ActionTypes.SET_ACTIVE_SETTINGS_TAB:
            return { ...state, activeSettingsTab: action.data };
        case ActionTypes.SET_ACTIVE_SETTINGS_ADMIN_PANEL_TAB:
            return { ...state, activeSettingsAdminPanelTab: action.data };
        default:
            return state;
    }
};
