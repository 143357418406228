import { styled } from '@mui/styles';
import { Box, CardHeader, Grid } from '@mui/material';

export const ChatbotParentBox = styled(Box)(({ theme }) => ({
        // height: '500px',
    '& .botplatformbox': {
        display: 'inline',
        width: '64px',
        height: '64px',
        padding: '0px',
        backgroundColor: 'transparent',
        backgroundSize: '64px',
        backgroundImage: 'url(https://www.botlibre.com/https://alice.botlibre.com/images/green_bot_button128.png)'
    },
    '& #sdkvideoplaybutton2': {
        display: 'none'
    }
}));
export const ChatbotFabBtn = styled(Box)(({ theme }) => ({
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    boxShadow: theme.shadows[4],
    transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    zIndex: '99',
    position: 'fixed',
    borderRadius: '50%',
    backgroundImage: 'url(/img/chatbot-icon.svg)',
    width: '64px',
    height: '64px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.light,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
        backgroundColor: '#00778B'
    },
    '&.todoFab': {
        backgroundColor: theme.palette.primary.main,
        bottom: 94,
        backgroundImage: 'url(/img/todoicon.png)'
    }
}));
export const BotCardHeader = styled(CardHeader)(({ theme }) => ({
    color: 'inherit',
    background: theme.palette.grey['50'],
    padding: '10px 13px'
}));
export const TypeBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    '& .type-active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.light
    }
}));
export const ChatbotFooterBox = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    padding: 0,
    width: '100%'
}));
export const ChatboxForm = styled('form')(({ theme }) => ({
    margin: 0,
    '& .MuiCardContent-root': {
        padding: '10px'
    },
    '& .MuiCardContent-root .MuiPaper-root': {
        paddingBottom: '4px',
        borderRadius: 0
    },
    '& .botplatformframebubble-div': {
        display: 'block'
    },
    '& .chat-input': {
        maxWidth: 'none',
        margin: '0 0 0 0',
        padding: '0',
        width: '100%'
    },
    '& .botplatformframechat-input': {
        maxWidth: 'none',
        margin: '0 0 0 0',
        padding: '0 8px',
        border: '1px solid #d5d5d5',
        fontSize: '13px',
        height: '33px',
        width: '100%'
    },
    '& #sendicon': { margin: '0 0 0 5px', padding: '0 8px', height: 'inherit' },
    '& .botplatformframebubble': {
        height: '84px',
        padding: '8px',
        border: '1px',
        display: 'block',
        color: '#585858',
        borderStyle: 'solid',
        borderColor: '#eeeeee',
        borderRadius: '10px',
        backgroundColor: '#eeeeee',
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '1px',
            left: '43px',
            borderWidth: '16px 0 0 15px',
            borderStyle: 'solid',
            borderColor: '#eeeeee transparent',
            display: 'block',
            width: '0'
        },
        '&:before': {
            borderColor: '#0000008a transparent',
            content: '""',
            position: 'absolute',
            bottom: '-2px',
            left: '40px',
            borderWidth: '18px 0 0 20px',
            borderStyle: 'solid',
            display: 'block',
            width: '0'
        }
    },
    '& #botplatformframebubble-text': {
        overflow: 'hidden auto',
        overflowWrap: 'break-word',
        // maxWidth: '296px',
        maxHeight: '70px'
    },
    '& .avatarBoxCenter': { display: 'flex !important', margin: 'auto' },
    '& #botplatformframebubble-div': {
        paddingBottom: '15px',
        position: 'relative'
    },
    '& #botplatformframeavatarbox': {
        display: 'flex',
        margin: 'auto'
    },
    '& #botplatformframeavatar-image-div': {
        display: 'none',
        width: '175px',
        '&img': { width: '175px' }
    },
    '& #botplatformframeavatar-video-div': {
        display: 'inline-block',
        width: '175px',
        minHeight: '200px',
        backgroundSize: '200px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(https://www.botlibre.com/media/a12546662-0.png)',
        position: 'fixed',
        opacity: '0',
        zIndex: '-1',
        '&video': { background: 'transparent', width: '175px' }
    },
    '& #botplatformframeavatar-canvas-div': {
        margin: 'auto',
        display: 'inline-block',
        width: '175px',
        minHeight: '200px'
    },
    '& #botplatformframeavatar-canvas': {
        background: 'transparent',
        width: '175px'
    }
}));
