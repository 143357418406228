import React, { useEffect, useState } from 'react';
import {
    Container,
    Typography,
    Box,
    Grid,
    CardMedia,
    Avatar,
    LinearProgress,
    Dialog,
    IconButton,
    OutlinedInput,
    InputAdornment,
    Button
} from '@mui/material';
import { styled } from '@mui/system';
import { Close } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsFeed, fetchNewsById } from 'redux/actions/SettingActions';
import { useParams } from 'react-router-dom';
import { infoColor } from 'assets/jss/material-kit-react';
import { withStyles } from '@mui/styles';
import { FooterFormControl } from 'assets/jss/FooterStyle';
import { submitProfileSettings } from 'redux/actions/SettingActions';
import { submitNewsletter } from 'redux/actions/GeneralCalActions';
import { setCookie, getCookie } from 'utils/Utils';
import { toast } from 'react-toastify';

// Define custom styles for the Carousel arrows
const StyledCarousel = styled(Carousel)(({ theme }) => ({
    '& .MuiButtonBase-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)'
        }
    },
    '& .MuiButtonBase-root:focus': {
        outline: 'none'
    }
}));

const CarouselLoader = withStyles({
    colorPrimary: {
        backgroundColor: '#9cd0d6'
    },
    root: { top: 23 },
    barColorPrimary: {
        backgroundColor: infoColor
    }
})(LinearProgress);

const NewsArticlePage = () => {
    const dispatch = useDispatch();
    const apiToken = getCookie('auth_token');
    const { newsList, newsArticle, profileDetail } = useSelector((state) => state.setting);
    const [publishedNews, setPublishedNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const { newsId } = useParams();
    // const news = JSON.parse(localStorage.getItem('myNews') || '[]');
    // const latestNews = JSON.parse(localStorage.getItem('latestNews') || '[]');
    // const dailyNews = JSON.parse(localStorage.getItem('dailyNews') || '[]');
    const [newsemail, setNewsemail] = useState('');
    const [show, setShow] = useState(false);

    const showDialog = () => {
        // setTimeout(() => {
        setShow(true);
        // }, 5000);
    };
    const closeDialog = () => {
        setShow(false);
    };

    const handleChangeNewseltter = (e) => {
        setNewsemail(e.target.value);
    };

    useEffect(() => {
        if (apiToken) {
            if (profileDetail.blog_notify === 'Off') {
                showDialog();
            }
        } else {
            if (!getCookie('news')) {
                showDialog();
            }
        }
    }, [profileDetail]);

    const handleNewsSeltterSubmit = () => {
        const emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        if (newsemail.toString().match(emailReg)) {
            if (apiToken) {
                dispatch(submitProfileSettings({ blog_email_notify: true }));
            } else {
                dispatch(submitNewsletter({ email: newsemail }));
                setCookie('news', newsemail);
            }

            setShow(false);
            setNewsemail('');
        } else {
            toast.error('Please enter valid email id.');
        }
    };

    useEffect(() => {
        dispatch(getNewsFeed('', 100, true));
    }, []);

    useEffect(() => {
        const published = newsList?.latest_news?.filter((val) => val?.is_published);
        setPublishedNews(published);
        if (published?.length > 0) {
            setLoading(false);
        }
    }, [newsList]);

    // useEffect(() => {
    //     if (!news || !latestNews || !dailyNews) return;
    //     const combinedNews = [...news, ...latestNews, ...dailyNews];
    //     const matchedNews = combinedNews.find((val) => {
    //         return (
    //             val?.id
    //                 .toLowerCase()
    //                 .replace(/\s+/g, '-')
    //                 .replace(/[^a-z0-9\-]/g, '') === newsId
    //         );
    //     });

    //     if (matchedNews) {
    //         dispatch(fetchNewsById(matchedNews.id));
    //     }
    // }, [newsId]);

    useEffect(() => {
        try {

            if (!newsList || !Object.values(newsList).some((list) => list && list.length > 0)) {
                return;
            }

            const combinedNews = [
                ...(newsList?.news || []),
                ...(newsList?.latest_news || []),
                ...(newsList?.daily_news || []),
            ];

            const normalizedNewsId = newsId.toLowerCase();

            const matchedNews = combinedNews.find((val) => {
                const newsTitle = val?.id || "";
                const formattedNewsTitle = newsTitle
                    .toLowerCase()
                    .replace(/\s+/g, "-")
                    .replace(/[^a-z0-9\-]/g, "");
                return formattedNewsTitle === normalizedNewsId;
            });

            if (matchedNews) {
                dispatch(fetchNewsById(matchedNews.id));
            } else {
                console.error("No matched news found for ID:", normalizedNewsId);
            }
        } catch (err) {
            console.error("Error in useEffect:", err);
        }
    }, [newsList, newsId, dispatch]);



    const isDashboardNews = newsArticle?.Type?.startsWith('daily-updates');
    const newsImage =
        newsArticle?.Type === 'daily-updates-treasury'
            ? '/img/treasury-bonds.jpeg'
            : newsArticle?.Type === 'daily-updates-corporate'
            ? '/img/corporate-bonds.jpeg'
            : newsArticle?.Type === 'daily-updates-econ'
            ? '/img/econ-market.jpg'
            : newsArticle?.Type === 'market_movers'
            ? '/img/nasdaq-snp-dow.jpg'
            : newsArticle?.Image
            ? newsArticle?.Image
            : '/img/logo.png';
    const formatDynamicContent = (content) => {
        const paragraphs = content?.split('\n\n');

        return paragraphs?.map((paragraph, index) => {
            const isHeadline = paragraph === paragraph?.toUpperCase();

            // Handle Headline
            if (isHeadline) {
                return (
                    <Typography
                        key={index}
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            marginBottom: '1.5rem',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            borderBottom: '2px solid #eee',
                            paddingBottom: '0.5rem'
                        }}
                    >
                        {paragraph}
                    </Typography>
                );
            }

            // Handle Regular Paragraph
            return (
                <Typography
                    key={index}
                    variant="body1"
                    sx={{
                        lineHeight: 1.8,
                        fontSize: '1.05rem',
                        marginBottom: '1.5rem',
                        textAlign: 'justify',
                        color: '#333'
                    }}
                >
                    {paragraph}
                </Typography>
            );
        });
    };
    return (
        <>
            {loading ? (
                <CarouselLoader />
            ) : (
                <StyledCarousel indicators={false} navButtonsAlwaysVisible>
                    {publishedNews?.map((news, index) => (
                        <a
                            key={index}
                            href={`/knowledge-and-research/news/${news?.id
                                .toLowerCase()
                                .replace(/\s+/g, '-')
                                .replace(/[^a-z0-9\-]/g, '')}`}
                            target="_blank"
                        >
                            <Box
                                key={news?.id}
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: { xs: '300px', sm: '400px', md: '500px', lg: '250px' },
                                    overflow: 'hidden'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <Avatar
                                        src={isDashboardNews ? '/img/white-logo.png' : news?.['News Source Logo']}
                                        alt={news?.['News Source']}
                                        sx={{ width: 30, height: 30, marginRight: '10px' }}
                                    />
                                    <Typography variant="subtitle2" sx={{ color: '#fff', fontWeight: 'bold' }}>
                                        {news?.['News Source']}
                                    </Typography>
                                </Box>
                                <CardMedia
                                    component="img"
                                    image={
                                        news?.Type === 'daily-updates-treasury'
                                            ? '/img/treasury-bonds.jpeg'
                                            : news?.Type === 'daily-updates-corporate'
                                            ? '/img/corporate-bonds.jpeg'
                                            : news?.Type === 'daily-updates-econ'
                                            ? '/img/econ-market.jpg'
                                            : news?.Type === 'market_movers'
                                            ? '/img/nasdaq-snp-dow.jpg'
                                            : news?.Image || '/img/logo.png'
                                    }
                                    alt={news?.Headline}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                        color: '#fff',
                                        padding: 2
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            padding: '20px',
                                            color: '#fff'
                                        }}
                                    >
                                        {news?.Headline}
                                    </Typography>
                                </Box>
                            </Box>
                        </a>
                    ))}
                </StyledCarousel>
            )}

            <Container maxWidth="md">
                <Box mb={3} mt={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Avatar
                            src={isDashboardNews ? '/img/white-logo.png' : newsArticle?.['News Source Logo']}
                            alt={newsArticle?.['News Source']}
                            sx={{ width: 30, height: 30, marginRight: '10px' }}
                        />
                        <Typography variant="h6" component="h1" gutterBottom>
                            {newsArticle?.['News Source']}
                        </Typography>
                    </Box>
                    <a href={newsArticle?.Link} target="_blank">
                        <Typography
                            sx={{
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                            variant="h3"
                            component="h1"
                            gutterBottom
                        >
                            {newsArticle?.Headline}
                        </Typography>
                    </a>
                    <Typography
                        variant="caption"
                        sx={{
                            display: 'block',
                            color: 'gray',
                            fontWeight: 'bold',
                            fontSize: '0.8rem'
                        }}
                    >
                        {newsArticle?.['Created At']}
                    </Typography>
                </Box>

                <Box mb={3}>
                    <CardMedia
                        component="img"
                        image={newsImage}
                        alt={newsArticle?.Headline}
                        sx={{
                            width: '600px',
                            height: '300px',
                            objectFit: 'cover',
                            margin: '0 auto'
                        }}
                    />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1" paragraph>
                            {formatDynamicContent(newsArticle?.Content)}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            {show && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: '20px',
                        left: '480px',
                        width: '295px',
                        borderRadius: '16px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        background: 'white',
                        padding: '16px',
                        color: 'white',
                        zIndex: 1300,
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            bottom: '-10px',
                            left: '130px',
                            width: '20px',
                            height: '20px',
                            background: 'white',
                            transform: 'rotate(45deg)',
                            boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.1)',
                            borderRadius: '2px'
                        }
                    }}
                >
                    <Box sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                        <IconButton onClick={closeDialog} sx={{ color: 'black' }}>
                            <Close />
                        </IconButton>
                    </Box>

                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black' }}>
                        Hey, TPE-HUB Reader
                    </Typography>
                    <Typography variant="subtitle2" sx={{ pb: 1, fontWeight: 500, color: 'black' }}>
                        Enter your email here to be notified of our next insightful article.
                    </Typography>

                    {/* Input for Email */}
                    <FooterFormControl
                        sx={{
                            borderRadius: '5px',
                            width: '100%',
                            backgroundColor: 'white',
                            boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <OutlinedInput
                            type="email"
                            value={newsemail}
                            onChange={handleChangeNewseltter}
                            placeholder="Your Email"
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#ffc107',
                                            color: '#000',
                                            ':hover': {
                                                backgroundColor: '#ffca28'
                                            }
                                        }}
                                        onClick={handleNewsSeltterSubmit}
                                    >
                                        Subscribe
                                    </Button>
                                </InputAdornment>
                            }
                        />
                    </FooterFormControl>

                    <Typography variant="caption" sx={{ color: 'red', fontWeight: 'bold', mt: 1, display: 'block' }}>
                        *Don't worry, we don’t spam.
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default NewsArticlePage;
