import React, { useState } from 'react';
import { Button, Card, ButtonGroup, Grid, IconButton, Tooltip, Typography, Box, Stack } from '@mui/material';
import { ChartCardParent, ChartSelectionBox, SummaryAllocationBox, SummaryTabPaper } from 'assets/jss/PortfolioStyle';
import PieChart from 'components/Charts/PieChart';
import TreeChart from 'components/Charts/TreeChart';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import CirclePackingChart from 'components/Charts/CirclePackingChart';
import { ArrowBackIos } from '@mui/icons-material';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import { numberWithCommas } from 'utils/Utils';


const PortfolioSummary = ({ summaryData, isBankFile, data, columns, topHoldings }) => {
    const initUnit = summaryData.pie?.map(() => '%');
    const [selectedChart, setSelectedChart] = useState('pie');
    const [selectedUnit, setSelectedUnit] = useState(initUnit);
    const [selectedChartData, setSelectedChartData] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState(null);
    const [historyStack, setHistoryStack] = useState([]);
    const data1 = {
        data,
        columns: columns.slice(2).map((col) => {
            if (!col?.disableFilters) {
                col.disableFilters = true;
                return col;
            }
            return col;
        })
    };
    const handleChartSelection = (name) => {
        setSelectedChart(name);
    };
    const toggleUnit = (index) => {
        setSelectedUnit((prev) => {
            const newSelectedUnit = [...prev];
            newSelectedUnit[index] = prev[index] === '%' ? '$' : '%';
            return newSelectedUnit;
        });
    };

    const handleOnClick = (node, event) => {
        setHistoryStack([...historyStack, selectedChartData]);
        if (node.data.subvalue) {
            setSelectedChartData(node.data.subvalue);
        } else {
            setFilteredTableData((_) => ({
                ...data1,
                data: data1?.data?.filter((obj) => obj.broad_asset_type?.toLowerCase() === node.id?.toLowerCase())
            }));
        }
    };

    const handleBack = () => {
        setSelectedChartData(historyStack.pop());
        setFilteredTableData(null);
    };

    if (filteredTableData)
        return (
            <Card elevation={2}>
                <Stack direction="row" margin="15px">
                    <Button
                        variant="text"
                        sx={{ padding: '5px 7px' }}
                        size="xs"
                        onClick={handleBack}
                        color="info"
                        startIcon={<ArrowBackIos />}
                    >
                        Back
                    </Button>
                    <Box width="15px" />
                </Stack>
                <EnhancedTable
                    customheight={300}
                    enableSearch={false}
                    enablePagination={false}
                    columns={filteredTableData?.columns}
                    data={filteredTableData?.data}
                    enableSort={false}
                    hideToolBar={true}
                />
            </Card>
        );
    else {
        return (
            <>
                <SummaryAllocationBox>
                    <ChartSelectionBox>
                        <Tooltip title="Pie Chart">
                            <IconButton
                                id="pie"
                                className={`subIconBtn ${selectedChart === 'pie' && 'selected'}`}
                                onClick={(e) => handleChartSelection('pie')}
                            >
                                <img alt="pie chart" src="img/pie-graph.png" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Tree chart">
                            <IconButton
                                id="tree"
                                className={`subIconBtn ${selectedChart === 'tree' && 'selected'}`}
                                onClick={(e) => handleChartSelection('tree')}
                            >
                                <img alt="tree map" src="img/treemap-chart.png" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Circle Packing Chart">
                            <IconButton
                                id="circle"
                                className={`subIconBtn ${selectedChart === 'circle' && 'selected'}`}
                                onClick={(e) => handleChartSelection('circle')}
                            >
                                <img alt="pie chart" src="img/circlePack.png" style={{ height: '30px' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Top 10 Holdings">
                            <IconButton
                                id="table"
                                className={`subIconBtn ${selectedChart === 'table' && 'selected'}`}
                                onClick={(e) => handleChartSelection('table')}
                            >
                                <img alt="pie chart" src="img/expenses-icon.png" style={{ height: '30px' }} />
                            </IconButton>
                        </Tooltip>
                    </ChartSelectionBox>
                    {Object.keys(summaryData).length > 0 ? (
                        <Grid container spacing={2}>
                            {selectedChart === 'pie' &&
                                summaryData.pie?.map((chartData, chartIndex) => {
                                    const label = chartData[0],
                                        val = chartData[1];
                                    if (chartData.length) {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={selectedChart === 'pie' ? 6 : 12}
                                                md={4}
                                                key={chartIndex + 'portfolioPieChart'}
                                            >
                                                <ChartCardParent elevation={2}>
                                                    <Typography m={'5px 0 0px 15px'} variant="subtitle1">
                                                        {isBankFile && chartIndex === 0 ? 'Broad Asset Type' : label}
                                                    </Typography>
                                                    {
                                                        <ButtonGroup
                                                            disableElevation
                                                            onClick={() => {
                                                                toggleUnit(chartIndex);
                                                            }}
                                                            className="toggleButtonGroup"
                                                        >
                                                            <Button
                                                                variant={selectedUnit[chartIndex] === '%' ? 'contained' : 'outlined'}
                                                                className="toggleButton"
                                                            >
                                                                %
                                                            </Button>
                                                            <Button
                                                                variant={selectedUnit[chartIndex] === '$' ? 'contained' : 'outlined'}
                                                                className="toggleButton"
                                                            >
                                                                $
                                                            </Button>
                                                        </ButtonGroup>
                                                    }
                                                    <PieChart
                                                        chartData={val}
                                                        label={label[0]}
                                                        halfGraph={false}
                                                        initCount={30}
                                                        handleOnClick={label[0] === 'Investment Type' ? handleOnClick : null}
                                                        hoverTooltipValueKey={
                                                            selectedUnit[chartIndex] === '%' ? 'percentageValue' : 'marketValue'
                                                        }
                                                        initYcount={{ 0: 160, 1024: 130, 768: 180, 425: 210, 375: 210 }}
                                                        initXcount={[-70, -125, -120, -70, -120]}
                                                    />
                                                </ChartCardParent>
                                            </Grid>
                                        );
                                    }
                                    return <></>;
                                })}
                            {selectedChart === 'tree' &&
                                summaryData.tree?.map((chartData, chartIndex) => {
                                    if (Object.keys(chartData).length) {
                                        return (
                                            <Grid item sm={12} xs={12} key={chartIndex + 'portfoliotreeChart'}>
                                                <ChartCardParent elevation={2}>
                                                    <TreeChart outerPadding={4} chartData={chartData} />
                                                </ChartCardParent>
                                            </Grid>
                                        );
                                    }
                                    return <></>;
                                })}
                            {selectedChart === 'circle' && (
                                <Grid item sm={12} xs={12} key={'portfolioCirclePackChart'}>
                                    <ChartCardParent elevation={2} customheight={'500px'}>
                                        <CirclePackingChart
                                            chartData={summaryData.circlePack}
                                            valueKey="value"
                                            idKey="id"
                                            labelKey="name"
                                        />
                                    </ChartCardParent>
                                </Grid>
                            )}
                            {selectedChart === 'table' && (
                                <Grid item xs={12} sm={12} key={'topHolding'}>
                                    <SummaryTabPaper elevation={3}>
                                        <Typography variant="subtitle1">
                                            Top 10 Holdings
                                        </Typography>
                                        <table style={{ width: '100%', height: 'auto', boxShadow: 'none', borderCollapse: 'unset' }}>
                                            <thead>
                                                <tr
                                                    style={{
                                                        textAlign: 'left',
                                                        borderBottom: '1px solid #ddd',
                                                        backgroundColor: 'rgb(231, 245, 255)',
                                                        color: 'rgb(89, 89, 91)'
                                                    }}
                                                >
                                                    <th style={{ padding: '8px' }}>Symbol</th>
                                                    <th style={{ padding: '8px' }}>Company Name</th>
                                                    <th style={{ padding: '8px' }}>Broad Asset Type</th>
                                                    <th style={{ padding: '8px' }}>Total Market Value</th>
                                                    <th style={{ padding: '8px' }}>Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topHoldings.map((row) => (
                                                    <tr key={row.Symbol} style={{ backgroundColor: 'inherit' }}>
                                                        <td style={{ padding: '0px 8px 0px 8px', textAlign: 'left' }}><b>{row.symbol}</b></td>
                                                        <td style={{ padding: '0px 8px 0px 8px', textAlign: 'left' }}><b>{row.company_name ? row.company_name : "-"}</b></td>
                                                        <td style={{ padding: '0px 8px 0px 8px', textAlign: 'left' }}>{row.broad_asset_type ? row.broad_asset_type : "-"}</td>
                                                        <td style={{ padding: '0px 8px 0px 8px', textAlign: 'left' }}>{numberWithCommas(row?.total_market_value)}</td>
                                                        <td style={{ padding: '0px 8px 0px 8px', textAlign: 'left' }}>{numberWithCommas(row?.percentage?.toFixed(2))}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </SummaryTabPaper>
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <ChartCardParent elevation={2} style={{ display: 'flex' }}>
                                    <NoDataFound style={{ margin: 'auto' }} msg="Result will be shown after uploading portfolio." />
                                </ChartCardParent>
                            </Grid>
                        </Grid>
                    )}
                </SummaryAllocationBox>
            </>
        );
    }
};
export default PortfolioSummary;
