import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip, IconButton, Card, Fade, Popper } from '@mui/material';
import { Info, Close, Chat as ChatIcon, Minimize } from '@mui/icons-material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { styled } from '@mui/styles';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import ChatBoxPopup from './ChatBoxPopup';
import styles from 'assets/jss/material-kit-react/views/chatBotPage';
import { openChatBotPopup } from 'redux/actions/ChatBotActions';
import { ChatbotParentBox, ChatbotFabBtn, BotCardHeader } from 'assets/jss/ChatBotStyle';
import { getChatHistory } from 'redux/actions/ChatBotActions';
import { getCookie } from 'utils/Utils';
import { fetchPortfolioList } from 'redux/actions/PortfolioActions';
import { getBudgetFiles } from 'redux/actions/BudgetActions';

const useStyles = makeStyles(styles);

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        color: theme.palette.primary.contrastText,
        fontSize: 14,
        padding: theme.spacing(1, 2),
        maxWidth: 350,
        boxShadow: theme.shadows[2],
        margin: '0px',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgb(204, 204, 204)',
        display: 'inline-flex',
        whiteSpace: 'pre-wrap'
    }
}));

const ChatBot = () => {
    const chatBotData = useSelector((state) => state.chatBot);
    const classes = useStyles();
    const chatbotBtnRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [open, setOpen] = useState(false);
    const chatbotBoxRef = useRef(null);
    const [minimized, setMinimized] = useState(false);
    const [position, setPosition] = useState({ x: window.innerWidth - 482, y: window.innerHeight - 465 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const token = getCookie('auth_token');
    const [cursorStyle, setCursorStyle] = useState('default');
    const [documentType, setDocumentType] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    const dispatch = useDispatch();

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        setDocumentType('');
        setPlacement('');
        setPosition({ x: window.innerWidth - 482, y: window.innerHeight - 415 });
    };

    const handleMinimize = () => {
        setMinimized((prev) => !prev);
    };

    useEffect(() => {
        if (chatBotData.togglechatbotpopup) {
            handleClick('top-end');
            dispatch(openChatBotPopup(false));
        }
    }, [chatBotData.togglechatbotpopup]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        const offsetX = e.clientX - position.x;
        const offsetY = e.clientY - position.y;
        setDragOffset({ x: offsetX, y: offsetY });
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        if (!minimized) {
            const x = e.clientX - dragOffset.x;
            const y = e.clientY - dragOffset.y;
            setPosition({ x, y });
        }
    };

    const handleMouseUp = () => setIsDragging(false);

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, dragOffset]);

    useEffect(() => {
        if (token) {
            dispatch(getBudgetFiles());
            dispatch(fetchPortfolioList());
            dispatch(getChatHistory());
        }
    }, [token]);

    const handleClick = (event, newPlacement) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setDocumentType(open ? '' : documentType);
        setPlacement(newPlacement);
        setMinimized(false);
    };

    return (
        <ChatbotParentBox>
            <ChatbotFabBtn ref={chatbotBtnRef} aria-label="Open chat bot" color="primary" onClick={(e) => handleClick(e, 'top-end')} />
            <Popper
                open={open}
                ref={chatbotBoxRef}
                onMouseDown={(e) => {
                    setCursorStyle('grabbing'); // Change to grabbing on mouse down
                    handleMouseDown(e); // Call the existing drag handler
                }}
                onMouseUp={() => setCursorStyle('grab')} // Reset to grab on mouse up
                onMouseEnter={() => setCursorStyle('grab')} // Set grab cursor on hover
                onMouseLeave={() => setCursorStyle('default')} // Reset cursor style on mouse leave
                sx={{
                    cursor: cursorStyle, // Apply cursor style conditionally
                    top: {
                        md: minimized
                            ? `${window.innerHeight - 50}px !important`
                            : isFullscreen
                            ? '0 !important'
                            : `${position.y - 100}px !important`,
                        xs: minimized ? `${window.innerHeight - 50}px !important` : isFullscreen ? '0 !important' : '80px !important'
                    },
                    left: {
                        md: minimized
                            ? `${window.innerWidth - 482}px !important`
                            : isFullscreen
                            ? '0 !important'
                            : `${position.x}px !important`,
                        xs: '0 !important'
                    },
                    width: isFullscreen && !minimized && '99vw !important',
                    transform: 'translate(0px, 0px) !important',
                    zIndex: isFullscreen ? 1300 : 'inherit',
                    height: isFullscreen ? '100vh !important' : `${documentType && documentType !== '' ? '540px' : '505px'} !important`,
                    maxHeight: isFullscreen && '100vh !important',
                    backgroundColor: isFullscreen ? 'white' : 'inherit',
                    boxShadow: isFullscreen ? '0 0 10px rgba(0,0,0,0.3)' : 'none',
                    overflow: isFullscreen ? 'auto' : 'hidden',
                    '& .botplatformframebubble': {
                        height: isFullscreen && (documentType ? '160px !important' : '190px !important')
                    },
                    '& .botplatformframebubble-text': {
                        minHeight: isFullscreen && '175px !important'
                    },
                    '& .chatMsgBox': {
                        height: isFullscreen ? (documentType ? '290px !important' : '318px !important') : '215px !important'
                    }
                }}
                anchorEl={anchorEl}
                placement={placement || 'top-end'}
                transition
                className={classNames('chatBotPopoverWrapper', classes.chatbot)}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Card className={classNames(classes.root, classes.chatBotPopover, { [classes.minimized]: minimized })}>
                            <BotCardHeader
                                avatar={<ChatIcon />}
                                action={[
                                    !minimized && (
                                        <Tooltip title={isFullscreen ? 'close fullscreen' : 'Open fullscreen'} placement="top">
                                            <IconButton aria-label="fullscreen" onClick={toggleFullscreen}>
                                                {isFullscreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    ),
                                    <Tooltip title="Info" placement="top">
                                        <StyledTooltip
                                            title="Welcome to TPE's Finance chatbot or finbot. 
                                            Please don't include any personal identifying information in the chat."
                                        >
                                            <IconButton aria-label="info" size="small">
                                                <Info />
                                            </IconButton>
                                        </StyledTooltip>
                                    </Tooltip>,
                                    // <>
                                    //     <ChatBotSettings
                                    //         chatTopicType={chatTopicType}
                                    //         handleTopicClick={handleTopicClick}
                                    //         gptVersionVal={gptVersionVal}
                                    //         handleGPTChange={handleGPTChange}
                                    //     />
                                    //     <DocumentAnalysisDialog
                                    //         token={token}
                                    //         docPopupOpen={docPopupOpen}
                                    //         handledocPopupClose={handledocPopupClose}
                                    //         documentType={documentType}
                                    //         handleDocumentTypeChange={handleDocumentTypeChange}
                                    //         documentIds={documentIds}
                                    //         handleDocumentIdsChange={handleDocumentIdsChange}
                                    //         userFiles={userFiles}
                                    //         classes={classes}
                                    //     />
                                    // </>,
                                    <Tooltip title={minimized ? 'Maximize' : 'Minimize'} placement="top">
                                        <IconButton aria-label="minimize" onClick={handleMinimize}>
                                            <Minimize />
                                        </IconButton>
                                    </Tooltip>,
                                    <Tooltip title="Close" placement="top">
                                        <IconButton aria-label="close" onClick={handleClose}>
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                ]}
                                title="Your Financial bot"
                            />
                            <ChatBoxPopup
                                chatBotData={chatBotData}
                                classes={classes}
                                documentType={documentType}
                                setDocumentType={setDocumentType}
                            />
                        </Card>
                    </Fade>
                )}
            </Popper>
        </ChatbotParentBox>
    );
};

export default ChatBot;
