import React, { lazy, Suspense } from 'react';
import LandingPage from 'views/LandingPage/index';
import CommonLayout from 'views/Layout/CommonLayout.js';
import LoginPage from 'views/LoginPage/LoginIndex.jsx';
// import Component from "./TemplateComponents/Components";
// import AboutUS from "views/AboutUs/Aboutus";
import BudgetCal from 'views/BudgetCalculator';
import ContactUs from 'views/ContactUs/index.jsx';
import DebtCalIndex from 'views/DebtCalculator/index';
import EquityDashBoard from 'views/EquityDashBoard/index';
import FxCal from 'views/FxCalculator/index.jsx';
import HomeValuation from 'views/HomeValuationCalculator/index.jsx';
import FixedIncomeDash from 'views/FixedIncomeDashboard/index';
import LifeInsuranceCal from 'views/LifeInsuranceCal/index.jsx';
import LoanDashBoard from 'views/LoanDashBoard/index';
import Restpassword from 'views/LoginPage/ResetPassword';
import ResetSuccess from 'views/LoginPage/ResetSuccess';
import OptionPricingCalculator from 'views/OptionPricingCalculator/index.jsx';
import PeerToPeerIndex from 'views/PeerToPeer/index.jsx';
import PortfolioAllocation from 'views/PortfolioAllocation/index.jsx';
import PropertyInvest from 'views/PropertyInvestTool/index.jsx';
import RetirementCalculator from 'views/RetirementCalculator/index.jsx';
import CollegeCalculator from 'views/CollegeCal/index';
import Settings from 'views/Settings/index';
import Pricing from 'views/Pricing/index.jsx';
import Signup from 'views/Signup/Signup';
import StockWatch from 'views/StockWatch/StockWatch';
import StudentLoanCal from 'views/StudentLoanCal/index';
import TaxEstimator from 'views/TaxEstimator/index.jsx';
import InflationBondCal from '../InflationBondCalculator/index.jsx';
import TipsCalculator from '../TipsCalculator';
import CompoundInterestCalculator from '../CompoundInterestCalculator';
import RecurringExpenseCalculator from '../RecurringExpenseCalculator';
import Typography from 'Typography.js';
import PortfolioValueUpload from 'views/PortfolioValueUpload/index';
// import BudgetAnalysisIndex from "views/BudgetAnalysis/index";
import Loader from 'components/CommonComponent/Loader';
import 'react-toastify/dist/ReactToastify.css';
import AnnuityTool from 'views/AnnuityTool';
import ComingSoon from 'views/ComingSoon';
import HomePuchaseIndex from 'views/HomePurchaseTool/index';
import MortgageRefinanceCalculator from 'views/MortgageRefinanceCal/index';
import MutualFundIndex from 'views/MutualFund';
import PageNotFound from 'views/PageNotFound';
import EcoDashBoard from 'views/EcoDashBoard';
import HouseAffordCalculator from 'views/HouseAffordabilityCal';
import Blog from 'views/Blog';
import BlogEditor from 'views/BlogEditor/index.jsx';
import CreditCardCalculator from 'views/CreditCardCalculator';
import PrivacyPolicy from 'views/PrivacyPolicy/index.jsx';
import KnowledgeResearchBlogs from 'views/KnowledgeResearch/ResearchBlogs/index.js';
import NewsFeed from 'views/KnowledgeResearch/ResearchNews/index.js';
import NewsArticlePage from 'views/News/index.jsx';
import RecurringPaymentCalculator from 'views/RecurringPaymentCalculator/index.js';

const AboutUS = lazy(() => import('views/AboutUs/index.jsx'));
const UserTodo = lazy(() => import('views/Todo/index'));
// const PortfolioValueUpload = lazy(() => import('views/PortfolioValueUpload/index.jsx'));
const BudgetAnalysisIndex = lazy(() => import('views/BudgetAnalysis/index'));
const MainRoutes = [
    {
        path: '/',
        element: <CommonLayout />,
        children: [
            {
                index: true,
                element: <LandingPage />
            },
            {
                path: 'about-us',
                element: (
                    <Suspense
                        fallback={
                            <CommonLayout>
                                <Loader />
                            </CommonLayout>
                        }
                    >
                        <AboutUS />
                    </Suspense>
                )
            },
            { path: 'contact-us', element: <ContactUs /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'home-valuation-calculator', element: <HomeValuation />, component: HomeValuation },
            { path: 'fx-conversion-calculator', element: <FxCal />, component: FxCal },
            { path: 'mutual-fund-etf-tool', element: <MutualFundIndex />, component: MutualFundIndex },
            { path: 'home-purchase-tool', element: <HomePuchaseIndex />, component: HomePuchaseIndex },
            { path: 'annuity-calculator', element: <AnnuityTool />, component: AnnuityTool },
            { path: 'student-loan-calculator', element: <StudentLoanCal />, component: StudentLoanCal },
            { path: 'option-pricing-calculator', element: <OptionPricingCalculator />, component: OptionPricingCalculator },
            { path: 'college-score-calculator', element: <CollegeCalculator />, component: CollegeCalculator },
            { path: 'knowledge-and-research/blog', element: <KnowledgeResearchBlogs />, component: KnowledgeResearchBlogs },
            { path: 'knowledge-and-research/blog/:blogId', element: <Blog />, component: Blog },
            { path: 'knowledge-and-research/news', element: <NewsFeed /> },
            { path: 'knowledge-and-research/news/:newsId', element: <NewsArticlePage /> },
            {
                path: 'finance-task-list',
                element: (
                    <Suspense
                        fallback={
                            <CommonLayout>
                                <Loader />
                            </CommonLayout>
                        }
                    >
                        <UserTodo />
                    </Suspense>
                ),
                component: UserTodo
            },
            { path: 'inflation-bond-calculator', element: <InflationBondCal /> },
            { path: 'tax-estimator-calculator', element: <TaxEstimator />, component: TaxEstimator },
            { path: 'budget-calculator', element: <BudgetCal />, component: BudgetCal },
            {
                path: 'budget-analysis-calculator',
                element: (
                    <Suspense
                        fallback={
                            <CommonLayout>
                                <Loader />
                            </CommonLayout>
                        }
                    >
                        <BudgetAnalysisIndex />
                    </Suspense>
                ),
                component: BudgetAnalysisIndex
            },
            { path: 'tips-calculator', element: <TipsCalculator />, component: TipsCalculator },
            { path: 'compound-interest-calculator', element: <CompoundInterestCalculator /> },
            { path: 'recurring-expense-calculator', element: <RecurringExpenseCalculator /> },
            { path: 'house-affordability-calculator', element: <HouseAffordCalculator />, component: HouseAffordCalculator },
            { path: 'mortgage-refinance-calculator', element: <MortgageRefinanceCalculator />, component: MortgageRefinanceCalculator },
            { path: 'debt-calculator', element: <DebtCalIndex />, component: DebtCalIndex },
            { path: 'credit-card-calculator', element: <CreditCardCalculator /> },
            { path: 'property-investment-tool', element: <PropertyInvest />, component: PropertyInvest },
            { path: 'loan-dashboard', element: <LoanDashBoard />, component: LoanDashBoard },
            { path: 'fixed-income-dashboard', element: <FixedIncomeDash />, component: FixedIncomeDash },
            { path: 'equity-dashboard', element: <EquityDashBoard />, component: EquityDashBoard },
            { path: 'economic-dashboard', element: <EcoDashBoard />, component: EcoDashBoard },
            { path: 'life-insurance-calculator', element: <LifeInsuranceCal />, component: LifeInsuranceCal },
            { path: 'settings', element: <Settings />, component: Settings },
            { path: 'subscription', element: <Pricing />, component: Pricing },
            { path: 'reset-success', element: <ResetSuccess />, component: ResetSuccess },
            { path: 'peer-to-peer-calculator', element: <PeerToPeerIndex />, component: PeerToPeerIndex },
            { path: 'stockwatch', element: <StockWatch />, component: StockWatch },
            { path: 'portfolio-valuation-calculator', element: <PortfolioValueUpload />, component: PortfolioValueUpload },
            { path: 'portfolio-allocation-calculator', element: <PortfolioAllocation />, component: PortfolioAllocation },
            { path: 'typography', element: <Typography /> },
            { path: 'retirement-calculator', element: <RetirementCalculator />, component: RetirementCalculator },
            { path: 'coming-soon', element: <ComingSoon /> },
            { path: 'regular-payment-and-bank-tracking-tool', element: <RecurringPaymentCalculator /> },
            { path: '*', element: <PageNotFound /> }
        ]
    },
    { path: 'reset-password', element: <Restpassword />, component: Restpassword },
    {
        path: 'login',
        element: <LoginPage />
    },
    {
        path: 'sign-up',
        element: <Signup />
    }
];

export default MainRoutes;
