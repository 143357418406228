import { Box, Breadcrumbs, Button, Card, Grid, IconButton, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
export const BudgetNotePaper = styled(Paper)(({ ps, theme }) => {
    return {
     display:'flex',
     margin:'10px 0 '
    };
});
export const AllocationCard = styled(Card)(({ ps, theme }) => {
           return {
               '& .input-label': {}
           };
       });
export const AllocationLabelGrid = styled(Grid)(({ ps, theme }) => {
           return {
            margin:'auto'
           };
       });
export const AllocationOutputCard = styled(Card)(({ ps, theme }) => {
           return {
            maxHeight:'500px',
            overflow:'scroll'
           };
       });
export const StudInputGrid = styled(Grid)(({ ps, theme }) => {
           return {
             paddingTop: "32px"
           };
       });
export const StudLabelGrid = styled(Grid)(({ ps, theme }) => {
           return {
    marginTop: "35px",
           };
       });
export const AnnuityAmtGrid = styled(Grid)(({ ps, theme }) => {
           return {
               marginTop: '40px',
               "& .annuityAmountLbl":{
                margin:'auto',
                textAlign:'center'
               },
            //    "& #Monthly_Annuity_Amount_slider":{
            //     width:'inherit',
            //     display:'flex',
            //     margin:'0 15px'
            //    }
           };
       });
export const StyledPortfolioBreadcrumb = styled(Breadcrumbs)(({ theme }) => {
           return {
            margin:'15px 0'
           };
       });
export const StyledAddRemoveIconBtn = styled(IconButton)(({ theme }) => {
           return {
               margin: 'auto',
               '&.addIcon': {
                   color: theme.palette.primary.main,
                   '&:hover': {
                       backgroundColor: theme.palette.primary.light
                   }
               },
               '&.removeIcon': {
                   color: theme.palette.secondary.main,
                   '&:hover': {
                       backgroundColor: theme.palette.secondary.light
                   }
               }
           };
       });
