import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Divider,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
    useTheme,
    Dialog,
    DialogContent,
    Grid
} from '@mui/material';
import { Add, Create, Delete, Done, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import Button from 'components/CustomButtons/Button';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import { a11yProps } from 'utils/Utils';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogsList } from 'redux/actions/BlogTypes';
import { deleteBlog, deleteBlogCategory } from 'redux/actions/BlogTypes';
import { fetchBlogCategory } from 'redux/actions/BlogTypes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useForm } from 'react-hook-form';
import RenderField from 'utils/RenderField';
import { updateBlogCategory } from 'redux/actions/BlogTypes';
import CustomDialogTitle from 'components/DialogTitle';
import BlogEditor from 'views/BlogEditor';
import CustomScroll from 'components/CustomScroll';
import Loader from 'components/CommonComponent/Loader';

const KnowledgeResearchAdmin = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [categorizeBlogs, setCategorizeBlogs] = useState({});
    const [viewCategories, setViewCategories] = useState(false);
    const [openBlogEditor, setOpenBlogEditor] = useState(false);
    const [editBlogId, setEditBlogId] = useState('');
    const [isBlogEdit, setIsBlogEdit] = useState(false);
    const [enableEdits, setEnableEdits] = useState([]);
    const [selectedRowsSet, setSelectedRowsSet] = useState({});
    const [updatedVal, setUpdatedVal] = useState({});
    const { blogsList, blogCategoryList, loader } = useSelector((state) => state.blogReducer);
    const handleTabChange = (_, newValue) => setSelectedTab(newValue);
    const [activeTab, setActiveTab] = useState(0);
    const [showImagePrev, setShowImagePrev] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleNavigation = (direction) => {
        const scrollContainers = document.querySelectorAll('.scrolll');
        if (scrollContainers.length < 2) return;
        const scrollContainer = scrollContainers[1];
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    let modifiedColumns = [];

    const handleEditData = (id) => {
        setEditBlogId(id);
        setIsBlogEdit(true);
        setOpenBlogEditor(true);
        setShowImagePrev(true);
    };

    const deleteSelectedData = (id) => {
        dispatch(deleteBlog(id));
    };

    const deleteSelectedCategory = (id) => {
        dispatch(deleteBlogCategory(id));
    };

    const { handleSubmit, control, setValue, reset } = useForm({
        mode: 'onChange'
    });

    const handleCategoryClose = () => {
        setViewCategories(false);
    };

    const handleBlogEditorClose = () => {
        dispatch(fetchBlogCategory());
        dispatch(fetchBlogsList());
        setOpenBlogEditor(false);
        setIsBlogEdit(false);
        setEditBlogId('');
        setShowImagePrev(false);
    };

    const handleBlogCreate = () => {
        setEditBlogId('');
        setIsBlogEdit(false);
        setOpenBlogEditor(true);
        setShowImagePrev(false);
    };

    const onSubmit = (input) => {
        const key = Object.keys(input)[0];
        const categoryId = key.split('__')[0];

        // create payload
        const payload = {};
        Object.keys(input).forEach((key) => {
            payload[key.split('__')[1]] = input[key];
        });

        dispatch(updateBlogCategory(categoryId, payload));

        setSelectedRowsSet({});
        setEnableEdits([]);
        reset();
    };

    const defaultCategories = ['Debt', 'Budget', 'Investments'];
    const requiredFields = ['Name'];

    // Create an editable cell renderer
    const EditableCell = (initialValue, type, row, cell, options = [], extra = { commaSep: true }) => {
        let valToPass = initialValue || '',
            custompattern = '';
        // We need to keep and update the state of the cell normally
        const [value, setCellValue] = useState(updatedVal);
        switch (type) {
            case 'select':
                valToPass = initialValue?.toString().toLowerCase();
                break;
            case 'searchSelect':
                valToPass = initialValue;
                break;
            default:
                custompattern = 'validString';
                break;
        }
        valToPass = valToPass || '';
        if (value[cell.column.id] !== undefined && value[cell.column.id] !== null) {
            valToPass = value[cell.column.id];
        }
        const id = [row.original.id, cell.column.id].join('_');
        // Check to make sure not all columns are editable
        return (
            <RenderField
                label={''}
                id={id}
                defaultValue={valToPass}
                name={`${row.values.id}__${cell.column.id}`}
                type={type}
                renderOption={(props, option) => {
                    return (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.flag !== '' ? (
                                <img loading="lazy" width="20" src={'img/flags/' + option.flag} alt="country flags" />
                            ) : (
                                ''
                            )}
                            <Typography variant="body2">{option.label}</Typography>
                        </Box>
                    );
                }}
                fieldName={cell.column.id}
                options={options}
                control={control}
                required={requiredFields.includes(cell.column.Header)}
                thousandSeparator={extra?.commaSep || false}
                customPatternName={extra?.custompattern || custompattern}
                setValue={setValue}
                {...extra}
            />
        );
    };

    const columns = useMemo(() => {
        const result = [
            {
                Header: 'Action',
                width: 120,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        <Tooltip title="Edit">
                            <IconButton aria-label="edit" onClick={(e) => handleEditData(row.original.id)}>
                                <Create />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(row.original.id)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            },
            {
                Header: 'Title',
                accessor: 'title',
                // disableFilters: true,
                Cell: ({ value, row }) =>
                    (
                        <a
                            href={`knowledge-and-research/blog/${row?.original?.title
                                ?.toLowerCase()
                                .replace(/\s+/g, '-')
                                .replace(/[^a-z0-9\-]/g, '')}`}
                            target="_blank"
                        >
                            {value}
                        </a>
                    ) || '-'
            },
            {
                Header: 'Status',
                accessor: 'is_published',
                disableFilters: true,
                Cell: ({ value }) => (value ? 'Published' : 'Draft')
            },
            {
                Header: 'Publish Date',
                accessor: 'published_at',
                disableFilters: true,
                Cell: ({ value }) => (value ? moment(value, 'DD-MM-YYYY HH:mm:ss').format('MM/DD/YYYY h:mm A') : '-')
            }
        ];

        const addAllCategoryColumn = {
            Header: 'category',
            accessor: 'category_name',
            disableFilters: true,
            Cell: ({ row }) => {
                const categories = row.original.category_name;
                return Array.isArray(categories) ? categories.join(' , ') : categories;
            }
        };
        modifiedColumns = [...result, addAllCategoryColumn];
        return result;
    });

    const categoryColumns = useMemo(() => {
        const result = [
            {
                Header: () => null,
                accessor: 'id',
                isVisible: false
            },
            {
                Header: 'Name',
                accessor: 'name',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return enableEdits?.[0]?.[row.original.id]
                        ? EditableCell(value, 'text', row, cell, [], { custompattern: /^[\s\S]+$/g })
                        : value;
                }
            },
            {
                Header: 'Action',
                width: 50,
                Cell: ({ row }) => {
                    let actionIcons;
                    if (enableEdits?.[0]?.[row.original.id]) {
                        actionIcons = (
                            <>
                                <Tooltip title="Save">
                                    <IconButton type="submit" aria-label="save">
                                        <Done />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Cancel">
                                    <IconButton
                                        aria-label="cancel"
                                        onClick={() => {
                                            setEnableEdits([]);
                                            setSelectedRowsSet({});
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    } else {
                        actionIcons = (
                            <>
                                <Tooltip title="Edit">
                                    <IconButton
                                        aria-label="edit"
                                        disabled={defaultCategories.includes(row.original.name)}
                                        onClick={() => {
                                            reset();
                                            setUpdatedVal({});
                                            setSelectedRowsSet({});
                                            setEnableEdits([{ [row.original.id]: row.original }]);
                                        }}
                                    >
                                        <Create />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton
                                        aria-label="delete"
                                        disabled={defaultCategories.includes(row.original.name)}
                                        onClick={() => {
                                            delete selectedRowsSet[row.original.id];
                                            deleteSelectedCategory(row.original.id);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                        // );
                    }
                    return <div className={'actionWrapper'}>{actionIcons}</div>;
                }
            }
        ];
        return result;
    }, [enableEdits, updatedVal]);

    useEffect(() => {
        dispatch(fetchBlogCategory());
        dispatch(fetchBlogsList());
    }, []);
    // useEffect(() => {
    //     localStorage.setItem('myBlog', JSON.stringify(blogsList));
    // }, [blogsList.length]);

    useEffect(() => {
        const data = {};
        const addAlldata = [];
        blogsList.forEach((blog) => {
            const categories = blog.category_name;
            if (Array.isArray(categories)) {
                const categoryString = categories.join(', ');

                const blogWithCategories = {
                    ...blog,
                    category_name: [categoryString]
                };

                categories.forEach((category) => {
                    if (!data[category]) {
                        data[category] = [];
                    }
                    data[category].push(blogWithCategories);
                });
            }
            addAlldata.push(blog);
        });
        const resultarr = { All: addAlldata, ...data };
        setCategorizeBlogs(resultarr);
    }, [blogsList]);

    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedTab(index);
    };

    const tabs = blogCategoryList.map((category) => {
        return { label: category.name };
    });
    const filteredBlogCategories = blogCategoryList.filter((category) => category.name !== 'All');

    // Initialize data based on selected filter when categorizeBlogs or selectedFilter changes
    useEffect(() => {
        if (categorizeBlogs) {
            const filteredBlogs = blogCategoryList.map((category) => {
                const allBlogs = categorizeBlogs[category.name] || [];
                let filteredCategoryBlogs = allBlogs;

                if (selectedFilter === 'draft') {
                    filteredCategoryBlogs = allBlogs.filter((blog) => !blog.is_published);
                } else if (selectedFilter === 'published') {
                    filteredCategoryBlogs = allBlogs.filter((blog) => blog.is_published);
                }

                return {
                    ...category,
                    filteredBlogs: filteredCategoryBlogs
                };
            });

            setFilteredData(filteredBlogs);
        }
    }, [categorizeBlogs, selectedFilter]);

    // Handle changes in the filter dropdown
    const handleFilterChange = (e) => {
        const val = e.target.value;
        setSelectedFilter(val);
    };

    return (
        <Card>
            {loader ? <Loader /> : ''}
            <CardContent>
                <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        '@media (max-width: 767px)': {
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }
                    }}
                >
                    <Typography variant="h6"> Blogs & Research </Typography>
                    <Stack
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="10px"
                        sx={{
                            '@media (max-width: 767px)': {
                                flexWrap: 'wrap',
                                alignItems: 'flex-start'
                            }
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            sx={{
                                width: '115px'
                            }}
                        >
                            <RenderField
                                handleOnChange={handleFilterChange}
                                name={'filter'}
                                control={control}
                                id={'filter'}
                                type={'select'}
                                options={[
                                    { label: 'All', value: 'all' },
                                    { label: 'Draft', value: 'draft' },
                                    { label: 'Published', value: 'published' }
                                ]}
                                defaultValue={'all'}
                                sx={{
                                    '@media (max-width: 767px)': {
                                        width: '100%'
                                    }
                                }}
                            />
                        </Grid>
                        <Tooltip title="Category List" arrow>
                            <Button color="success" size="xs" onClick={() => setViewCategories(true)}>
                                <VisibilityIcon />
                                Categories
                            </Button>
                        </Tooltip>
                        <Tooltip title="Create New Blog" arrow>
                            <Button onClick={handleBlogCreate} color="info" size="xs">
                                <Add />
                                Blog
                            </Button>
                        </Tooltip>
                    </Stack>
                </Stack>
                <Box height="10px" />
                <Divider sx={{ borderColor: theme.palette.grey[300] }} />
                <Box height="10px" />
                <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 0 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleNavigation('backward')}
                        >
                            {' '}
                            &lt;
                        </div>
                        <div
                            style={{
                                overflowX: 'auto',
                                display: 'flex'
                            }}
                            className="scrolll"
                            onScroll={handleScroll}
                        >
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        gap: 4,
                                        alignItems: 'center',
                                        flex: '0 0 auto',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        borderBottom: '3px solid transparent',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                        color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                                    }}
                                    onClick={() => handleTabClick(index)}
                                >
                                    <div>{tab.label}</div>
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 2 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleNavigation('forward')}
                        >
                            &gt;
                        </div>
                    </div>
                </SwipeableViews>
                {/* <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} /> */}
                {/* <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    sx={{ background: 'white' }}
                    variant="scrollable"
                    allowScrollButtonsMobile={true}
                >
                    {blogCategoryList.map((category, index) => {
                        return <Tab key={index} label={category.name} {...a11yProps(index)} />;
                    })}
                </Tabs> */}
                <SwipeableViews
                    index={selectedTab}
                    onChangeIndex={(index) => {
                        setActiveTab(index);
                        setSelectedTab(index);
                    }}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                >
                    {filteredData.map((category, index) => (
                        <PortfolioTabPanel value={selectedTab} index={index} dir={theme.direction} key={index}>
                            {category.filteredBlogs?.length > 0 ? (
                                <EnhancedTable
                                    columns={category.name === 'All' ? modifiedColumns : columns}
                                    data={category.filteredBlogs || []}
                                    enablePagination={false}
                                    enableSearch={true}
                                    enableSort={true}
                                />
                            ) : (
                                <NoDataFound
                                    msg={
                                        selectedFilter === 'all'
                                            ? 'No data found.'
                                            : `No data found for ${selectedFilter === 'draft' ? 'draft' : 'published'} blogs.`
                                    }
                                    style={{ padding: '20px', fontWeight: '700' }}
                                />
                            )}
                        </PortfolioTabPanel>
                    ))}
                </SwipeableViews>
            </CardContent>
            <Dialog open={viewCategories} onClose={handleCategoryClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={handleCategoryClose}>
                    Blog Categories
                </CustomDialogTitle>
                <Divider />
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ width: '500px' }}>
                            <EnhancedTable columns={categoryColumns} data={filteredBlogCategories || []} enablePagination={false} />
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog disableEnforceFocus open={openBlogEditor} onClose={handleBlogEditorClose} aria-labelledby="form-todo-title" maxWidth>
                <CustomDialogTitle id="form-todo-title" onClose={handleBlogEditorClose}>
                    Blog Editor
                </CustomDialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ width: '1000px' }}>
                        <BlogEditor
                            blogId={editBlogId}
                            isUpdate={isBlogEdit}
                            closeEditor={handleBlogEditorClose}
                            showImagePrev={showImagePrev}
                            setShowImagePrev={setShowImagePrev}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </Card>
    );
};

export default KnowledgeResearchAdmin;
