import Email from '@mui/icons-material/Email';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CardContent, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeStyles, withStyles } from '@mui/styles';
import { Box, styled } from '@mui/system';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CustomImg } from 'assets/jss/HeaderStyle';
import { BgBox, LeftRightGrid, LoginBox, LoginCard, SubboxWrapperBox } from 'assets/jss/LoginStyle';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { StyledInputAdornment } from 'assets/jss/TextFieldStyle';
import Loader from 'components/CommonComponent/Loader';
import useWindowDimensions from 'components/CommonComponent/UseWindowDimensions';
import Meta from 'components/meta';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { submitForgotResetPassword, submitLoginData } from 'redux/actions/AuthActions';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Input as BaseInput } from '@mui/base/Input';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
import { submitMultifactorAuth } from 'redux/actions/AuthActions';
const useStyles = makeStyles(styles);
const MyIconButton = withStyles({
    root: {
        padding: 0
    }
})(IconButton);
let _timeout = 0;
const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2'
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025'
};

const InputElement = styled('input')(
    ({ theme }) => `
        width: 40px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 0;
        border-radius: 8px;
        text-align: center;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'};
      
        &:hover {
          border-color: ${blue[400]};
        }
      
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
        }
      
        /* firefox */
        &:focus-visible {
          outline: 0;
        }
      `
);

const OTP = ({ separator, length, value, onChange }) => {
    const inputRefs = React.useRef(new Array(length).fill(null));

    const focusInput = (targetIndex) => {
        const targetInput = inputRefs.current[targetIndex];
        targetInput.focus();
    };

    const selectInput = (targetIndex) => {
        const targetInput = inputRefs.current[targetIndex];
        targetInput.select();
    };

    const handleKeyDown = (event, currentIndex) => {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case ' ':
                event.preventDefault();
                break;
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }
                break;
            case 'ArrowRight':
                event.preventDefault();
                if (currentIndex < length - 1) {
                    focusInput(currentIndex + 1);
                    selectInput(currentIndex + 1);
                }
                break;
            case 'Delete':
                event.preventDefault();
                onChange((prevOtp) => {
                    const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });

                break;
            case 'Backspace':
                event.preventDefault();
                if (currentIndex > 0) {
                    focusInput(currentIndex - 1);
                    selectInput(currentIndex - 1);
                }

                onChange((prevOtp) => {
                    const otp = prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
                    return otp;
                });
                break;

            default:
                break;
        }
    };

    const handleChange = (event, currentIndex) => {
        const currentValue = event.target.value;
        let indexToEnter = 0;

        while (indexToEnter <= currentIndex) {
            if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                indexToEnter += 1;
            } else {
                break;
            }
        }
        onChange((prev) => {
            const otpArray = prev.split('');
            const lastValue = currentValue[currentValue.length - 1];
            otpArray[indexToEnter] = lastValue;
            return otpArray.join('');
        });
        if (currentValue !== '') {
            if (currentIndex < length - 1) {
                focusInput(currentIndex + 1);
            }
        }
    };

    const handleClick = (event, currentIndex) => {
        selectInput(currentIndex);
    };

    const handlePaste = (event, currentIndex) => {
        event.preventDefault();
        const clipboardData = event.clipboardData;

        // Check if there is text data in the clipboard
        if (clipboardData.types.includes('text/plain')) {
            let pastedText = clipboardData.getData('text/plain');
            pastedText = pastedText.substring(0, length).trim();
            let indexToEnter = 0;

            while (indexToEnter <= currentIndex) {
                if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
                    indexToEnter += 1;
                } else {
                    break;
                }
            }

            const otpArray = value.split('');

            for (let i = indexToEnter; i < length; i += 1) {
                const lastValue = pastedText[i - indexToEnter] ?? ' ';
                otpArray[i] = lastValue;
            }

            onChange(otpArray.join(''));
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {new Array(length).fill(null).map((_, index) => (
                <React.Fragment key={index}>
                    <BaseInput
                        slots={{
                            input: InputElement
                        }}
                        aria-label={`Digit ${index + 1} of OTP`}
                        slotProps={{
                            input: {
                                ref: (ele) => {
                                    inputRefs.current[index] = ele;
                                },
                                onKeyDown: (event) => handleKeyDown(event, index),
                                onChange: (event) => handleChange(event, index),
                                onClick: (event) => handleClick(event, index),
                                onPaste: (event) => handlePaste(event, index),
                                value: value[index] ?? ''
                            }
                        }}
                    />
                    {index === length - 1 ? null : separator}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default function LoginIndex(props) {
    const { height } = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.login);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    // const [keepPass, setKeepPass] = useState(false);
    const [open, setOpen] = useState(false);
    const [deviceId, setDeviceId] = useState('');
    const [authOpen, setAuthOpen] = useState(false);
    const [otp, setOtp] = useState('');
    const [valid, setValid] = useState(false);

    const classes = useStyles();
    if (getCookie('auth_token') !== null) {
        navigate('/');
    }
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            helpdesc: '',
            name: '',
            number: ''
        }
    });

    useEffect(() => {
        const fetchDeviceId = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            setDeviceId(result.visitorId);
        };
        fetchDeviceId();
    }, []);

    useEffect(() => {
        if (getCookie('auth_token') !== null) {
            navigate('/');
        }
        if (getCookie('user_pass') !== null) {
            setValue('password', getCookie('user_pass'));
        }
    }, []);

    const handleClickShowPassword = (e) => setShowPassword({ ...showPassword, [e]: !showPassword[e] });
    const handleLogin = async (data) => {
        setPass(data.password);
        const payload = { ...data, device_fingerprint: deviceId };
        dispatch(submitLoginData(payload, null, false , setAuthOpen));
    };

    const handleVerify = () => {
        if (valid) {
            const payload = { email: email, device_fingerprint: deviceId, otp: otp };
            dispatch(submitMultifactorAuth(payload));
            const loginPayload = { email: email, password: pass, device_fingerprint: deviceId };
            dispatch(submitLoginData(loginPayload, navigate, false));
            setAuthOpen(false);
        }
    };
    const handleClickOpen = () => setOpen(true);
    const handleForgotDialogClose = () => setOpen(false);
    const handleAuthDialogClose = () => {
        setAuthOpen(false);
        setValid(false);
    };
    const handleAuthEmail = (data) => {
        setValid(true);
        setEmail(data.email);
        const payload = { email: data.email, device_fingerprint: deviceId };
        dispatch(submitMultifactorAuth(payload, 'post'));
    };
    const handleResetCode = () => {
        if (forgotEmail === '' || !forgotEmail.match(emailReg)) {
            toast.error('Please enter email id.');
        } else {
            setOpen(false);
            dispatch(submitForgotResetPassword({ email: forgotEmail }));
        }
    };
    const handleForgotEmail = (e) => {
        const val = e.target.value;
        if (_timeout) clearTimeout(_timeout);
        _timeout = setTimeout(() => {
            setForgotEmail(val);
        }, 1000);
    };
    return (
        <BgBox>
            <Meta
                title="Login | TPE Hub"
                description="Log in to your account to access personalized features and manage your settings. Enter your credentials to securely access your account."
                page="/login"
            />
            <ToastContainer />
            <Grid container>
                {auth.loader ? <Loader /> : ''}
                <LeftRightGrid item xs={12} md={5} className="form-grid">
                    <MenuItem component={Link} disableGutters to={'/'} className="logo-menu">
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            disableRipple={true}
                        >
                            <CustomImg src="img/TPE-logo.svg" className="login-tpe-logo" />
                        </IconButton>
                    </MenuItem>
                    <LoginBox minheight={height}>
                        <LoginCard>
                            <CardContent>
                                <Typography variant="subtitle1" sx={{ mb: 3.5 }} textAlign="center">
                                    Good to See You Again
                                </Typography>
                                {/* <Typography variant="body1" sx={{ mb: 1.5 }}>
                                    Login in to continue
                                </Typography> */}
                                {/* <Grid container className="gridcontainer-SSO">
                                    <SSOSignGrid item xs={12} sm={4}>
                                        <SSOSignBtn>
                                            <img src="img/facebook-login.svg" />
                                        </SSOSignBtn>
                                    </SSOSignGrid>
                                    <SSOSignGrid item xs={12} sm={4}>
                                        {' '}
                                        <SSOSignBtn>
                                            <img src="img/google-login.svg" />
                                        </SSOSignBtn>
                                    </SSOSignGrid>
                                </Grid> */}
                                {/* <DividerWithTextBox>
                                    <Divider>or login with</Divider>
                                </DividerWithTextBox> */}
                                <form onSubmit={handleSubmit(handleLogin)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="email"
                                                control={control}
                                                label="Email Address"
                                                id="user_email"
                                                type="text"
                                                fullWidth
                                                BootstrapInput={true}
                                                required
                                                customPatternName="email"
                                                placeholder="trump@maralago.com"
                                                adornment={true}
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <Email className={classes.inputIconsColor} />
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="password"
                                                control={control}
                                                label="Password"
                                                asterisk
                                                id="password"
                                                fullWidth
                                                BootstrapInput={true}
                                                type={showPassword['password'] ? 'text' : 'password'}
                                                required
                                                adornment={true}
                                                customPatternName="password"
                                                placeholder="Enter password"
                                                helperText="Use 8 or more characters with a mix of capital and small letters, numbers & symbols"
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <MyIconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => handleClickShowPassword('password')}
                                                        >
                                                            {showPassword['password'] ? <Visibility /> : <VisibilityOff />}
                                                        </MyIconButton>
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs textAlign={'end'}>
                                            <CustomButton
                                                id="forgotPassBtn"
                                                onClick={handleClickOpen}
                                                simple
                                                size="small"
                                                component={Link}
                                                to=""
                                            >
                                                Forgot Password?
                                            </CustomButton>
                                        </Grid>
                                        <Dialog open={open} onClose={handleForgotDialogClose} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Forgot your password?</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    id="login_name"
                                                    label="Email Address"
                                                    type="email"
                                                    fullWidth
                                                    onChange={(e) => handleForgotEmail(e)}
                                                />
                                                <DialogContentText>
                                                    <Typography variant="caption">
                                                        Please enter the email you use to sign in to TPE.
                                                    </Typography>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <CustomButton onClick={handleForgotDialogClose}>Cancel</CustomButton>
                                                <CustomButton onClick={handleResetCode} variant="contained">
                                                    Send Reset code
                                                </CustomButton>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                    <CalCardActions>
                                        <CustomButton
                                            className="login-btn"
                                            type="submit"
                                            ps={'9px 30px'}
                                            variant="contained"
                                            disabled={isSubmitting}
                                        >
                                            Log In
                                        </CustomButton>
                                    </CalCardActions>
                                    <Box textAlign={'end'}>
                                        <Typography variant="h6">
                                            Not a member yet?{' '}
                                            <CustomButton simple size="small" component={Link} to="/sign-up">
                                                Register now
                                            </CustomButton>
                                        </Typography>
                                    </Box>
                                </form>
                            </CardContent>

                            <Dialog open={authOpen} onClose={handleAuthDialogClose} aria-labelledby="form-dialog-title">
                                <form onSubmit={handleSubmit(handleAuthEmail)}>
                                    <DialogTitle id="form-dialog-title">Two Factor Authentication</DialogTitle>
                                    <DialogContent>
                                        <RenderField
                                            name="email"
                                            control={control}
                                            label="Email Address"
                                            id="user_email"
                                            type="text"
                                            fullWidth
                                            BootstrapInput={true}
                                            required
                                            disabled
                                            customPatternName="email"
                                            placeholder="trump@maralago.com"
                                            adornment={true}
                                            endAdornment={
                                                <StyledInputAdornment position="end">
                                                    <Email className={classes.inputIconsColor} />
                                                </StyledInputAdornment>
                                            }
                                        />
                                        {valid && <OTP separator={<span>-</span>} value={otp} onChange={setOtp} length={6} />}
                                        <DialogContentText>
                                            {!valid ? (
                                                <Typography variant="caption">This email will having your OTP.</Typography>
                                            ) : (
                                                <Typography variant="caption">Please enter your OTP here... </Typography>
                                            )}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <CustomButton onClick={handleAuthDialogClose}>Cancel</CustomButton>
                                        {!valid ? (
                                            <CustomButton className="code-btn" type="submit" ps={'9px 30px'} variant="contained">
                                                Send code
                                            </CustomButton>
                                        ) : (
                                            <CustomButton id="verifyOtpBtn" onClick={handleVerify} simple size="small">
                                                Verify Code
                                            </CustomButton>
                                        )}
                                    </DialogActions>
                                </form>
                            </Dialog>
                        </LoginCard>
                        {/* </SubboxWrapperBox> */}
                    </LoginBox>
                </LeftRightGrid>
                <LeftRightGrid item xs={12} md={7} className="right-side">
                    <SubboxWrapperBox>
                        <Typography variant="subtitle1">A financial move</Typography>
                        <Typography variant="subtitle1" ml={'37%'}>
                            is smart move
                        </Typography>
                        {/* <PiggyBox></PiggyBox> */}
                        <img src="/img/login-piggy.png" className="login-piggy" alt="tpe login piggy" />
                    </SubboxWrapperBox>
                </LeftRightGrid>
            </Grid>
        </BgBox>
    );
}
