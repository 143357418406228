import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, CardMedia, Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestNews } from 'redux/actions/SettingActions';

// Styled Carousel
const StyledCarousel = styled(Carousel)(({ theme }) => ({
    '& .MuiButtonBase-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)',
        },
    },
    '& .MuiButtonBase-root:focus': {
        outline: 'none',
    },
}));

const NewsFeedView = () => {
    const dispatch = useDispatch();
    const { latestNewsList } = useSelector((state) => state.setting);

    const [news, setNews] = useState([]);

    useEffect(() => {
        dispatch(getLatestNews());
    }, [dispatch]);

    useEffect(() => {
        if (latestNewsList?.latest_news) {
            const publishedNews = latestNewsList.latest_news.filter((item) => item?.is_published);
            setNews(publishedNews || []);
        }
    }, [latestNewsList]);

    const timeAgo = (dateString) => {
        if (!dateString) return 'UNKNOWN TIME';

        const date = new Date(dateString);
        const now = new Date();
        const secondsAgo = Math.floor((now - date) / 1000);

        if (secondsAgo < 60) return `${secondsAgo} SEC AGO`;
        if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)} MIN AGO`;
        if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)} HOUR${secondsAgo > 1 ? 'S' : ''} AGO`;

        return `${Math.floor(secondsAgo / 86400)} DAY${Math.floor(secondsAgo / 86400) > 1 ? 'S' : ''} AGO`;
    };

    const getNewsImage = (news) => {
        switch (news?.Type) {
            case 'daily-updates-treasury':
                return '/img/treasury-bonds.jpeg';
            case 'daily-updates-corporate':
                return '/img/corporate-bonds.jpeg';
            case 'daily-updates-econ':
                return '/img/econ-market.jpg';
            case 'market_movers':
                return '/img/nasdaq-snp-dow.jpg';
            default:
                return news?.Image || '/img/logo.png';
        }
    };

    return (
        <Grid sx={{ marginTop: '20px' }}>
            <Typography variant="h4" sx={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', padding: '15px'}}>
                Our News Updates
            </Typography>
            { news.length !== 0 ? 
            <StyledCarousel indicators={false} navButtonsAlwaysVisible>
                {news.map((newsItem, index) => (
                    <a
                        key={index}
                        href={`/knowledge-and-research/news/${newsItem?.id
                            ?.toLowerCase()
                            .replace(/\s+/g, '-')
                            .replace(/[^a-z0-9\-]/g, '')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Box sx={{ position: 'relative', height: '100%' }}>
                            <CardMedia
                                component="img"
                                image={getNewsImage(newsItem)}
                                alt={newsItem?.Headline}
                                sx={{
                                    width: '100%',
                                    height: '376px',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                }}
                            />
                            <Typography
                                variant="h4"
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    padding: '20px',
                                    color: '#fff',
                                    background: 'rgba(0, 0, 0, 0.5)',
                                    borderRadius: "0px 0px 10px 10px"
                                }}
                            >
                                {newsItem?.Headline}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    position: 'absolute',
                                    bottom: '0px',
                                    left: '20px',
                                    color: '#fff',
                                }}
                            >
                                {timeAgo(newsItem?.['Created At'])}
                            </Typography>
                        </Box>
                    </a>
                ))}
            </StyledCarousel>
            : 
            <Typography sx={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', padding: '25px'}}>
                Sorry, no news available
            </Typography>
            }
        </Grid>
    );
};

export default NewsFeedView;
