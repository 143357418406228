import { Box, Button, CardContent, FormControlLabel, Grid, Paper, Popover } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import NoDataFound from "components/CommonComponent/NoDataFound";
import CustomSelect from "components/CustomSelect/CustomSelect";
import React, { useEffect } from "react";
import { deleteRecurringFiles } from "redux/actions/RecurringPaymentActions";
import { toast } from "react-toastify";
import { TopHeadingPaper } from "assets/jss/GeneralStyle";
import { UploadCardParent } from "assets/jss/GeneralStyle";
import { UploadStyledAccordion } from "assets/jss/GeneralStyle";

const RecurringPaymentAnalysisOutput = (props) => {
    const { classes } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [sampleTable, setSampleTable] = React.useState({});
    const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState(false);
    const container = React.useRef(null);
    const open = Boolean(anchorEl);
    const id = open ? "action-popup" : undefined;

    // to open action popup
    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

    // to close action popup on outside click
    const handlePopoverClose = () => setAnchorEl(null);

    const handleDeleteConfirmation = () => {
        if (selectedFile.length === 0) {
            return toast.error("Please check at least one checkbox to delete file.");
        }
        setConfirmDeleteDialog(true);
    }

    const handleCancelDeleteConfirmation = () => {
        handlePopoverClose();
        setConfirmDeleteDialog(false);
    };

    //handle selected files to delete and pass those ids to parent component
    const handleFileDelete = () => {
        setSelectedFile([]);
        handleCancelDeleteConfirmation();
        props.dispatch(deleteRecurringFiles(selectedFile));
    };

    // store checked file
    const handleSelectedFileCheck = (e, id) => {
        if (e.target.checked === false) {
            const currId = selectedFile.indexOf(id);
            if (currId > -1) {
                setSelectedFile(selectedFile.filter((val) => val !== id));
            }
        } else {
            setSelectedFile([...selectedFile, id]);
        }
    };
    useEffect(() => {
        // make column list for output table
        if (props.data.length > 0) {
            const ignoreFields = ["id"];
            let formatChartData = [];
            props.data.forEach((val) => {
                const individualChartData = [];
                {
                    val?.data.length !== 0 &&
                    Object.keys(val.data[0]).forEach((subVal) => {
                        if (!ignoreFields.includes(subVal)) {
                            individualChartData.push({
                                Header: subVal,
                                accessor: subVal,
                                disableFilters: true,
                            });
                        }
                    });
                }
                formatChartData.push(individualChartData);
            })
            setSampleTable({
                data: props.data,
                column: formatChartData,
            });
        }
    }, [props.data]);
    return (
        <>
            <TopHeadingPaper elevation={2}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom className="top-heading-text">
                    My Recurring Payments(s)
                </Typography>
                {props.data.length ? (
                    <IconButton aria-label="settings" onClick={handlePopoverOpen} aria-describedby={id}>
                        <MoreVertIcon />
                    </IconButton>
                ) : (
                    <></>
                )}
            </TopHeadingPaper>
            <UploadCardParent elevation={2} className={'portoutput'} id="portfolioOutputWrapper">
                <CardContent>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem button onClick={(event) => handleDeleteConfirmation()}>
                                <ListItemText primary="Delete" />
                            </ListItem>
                        </List>
                    </Popover>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            {props.data.length > 0 ? (
                                props.data.map(({ data, file_name, id }, Uindex) => {
                                    return (
                                        <UploadStyledAccordion key={Uindex + 'uploadedFileDetail'}>
                                            <AccordionSummary
                                                ref={container}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <FormControlLabel
                                                    className={classes.formControlLabel}
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedFile.indexOf(id) > -1 ? true : false}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }}
                                                            name={id}
                                                            id={id}
                                                            onChange={(e) => handleSelectedFileCheck(e, id)}
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Box
                                                                        component="div"
                                                                        textOverflow="ellipsis"
                                                                        style={{ wordBreak: 'break-all' }}
                                                                    >
                                                                        {file_name}
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Paper elevation={0}>
                                                    {Object.keys(sampleTable).length > 0 ? (
                                                        sampleTable.column[Uindex] ? (
                                                            <EnhancedTable
                                                                enablePagination={false}
                                                                enableSearch={false}
                                                                columns={sampleTable.column[Uindex]}
                                                                data={sampleTable?.data[Uindex]?.data}
                                                                enableSort={false}
                                                                hideToolBar={true}
                                                                sampleTable={true}
                                                            />
                                                        ) : (
                                                            ''
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </Paper>
                                            </AccordionDetails>
                                        </UploadStyledAccordion>
                                    );
                                })
                            ) : (
                                <NoDataFound msg="Results will be shown after uploading." />
                            )}
                        </Grid>
                    </Grid>
                    <Dialog
                        open={confirmDeleteDialog}
                        onClose={handleCancelDeleteConfirmation}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you really want to delete these Recurring Payment File file(s) ? This process can not be undone.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={handleCancelDeleteConfirmation}>
                                Cancel
                            </Button>
                            <Button color="primary" autoFocus onClick={handleFileDelete}>
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </UploadCardParent>
        </>
    );
};
export default RecurringPaymentAnalysisOutput;
