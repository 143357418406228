import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import { fetchMarketMoverData } from 'redux/actions/EquityDashboardActions';
import { SummaryTabPaper } from 'assets/jss/PortfolioStyle';
import { Container, Tab, Tabs, Typography, useTheme, Grid } from '@mui/material';
import { Bar, XAxis, YAxis, Tooltip, BarChart } from 'recharts';
import { a11yProps } from 'utils/Utils.js';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel.js';
import { makeStyles } from '@mui/styles';
import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import { numberWithCommas } from 'utils/Utils';
// import moment from "moment";
import useSocket from 'utils/SocketIo';
import Globals from 'Globals';

const useStyles = makeStyles(styles);

const MarketMoverTable = (props) => {
    const dispatch = useDispatch();
    const [resultData, setResult] = useState([]);
    const [subSelectedTab, setSubSelectedTab] = useState(0);
    const theme = useTheme();
    const [isConnected, setIsConnected] = useState(false);
    const previousSymbols = useRef([]);
    const socket = useSocket(`${Globals.SIO_URL}`);

    const classes = useStyles();
    const subSelectedTabRef = useRef(subSelectedTab);
    const subResultDataRef = useRef(resultData);

    // var today_date = new Date(),
    // new_date = today_date.getFullYear() + '-' + (today_date.getMonth() + 1) + '-' + today_date.getDate();
    // const [lastTradeTime, setLastTradeTime] = useState(new_date);

    useEffect(() => {
        subSelectedTabRef.current = subSelectedTab;
    }, [subSelectedTab]);

    const tabMapping = [
        { keyGainer: 'dow_top_gainer', keyDecliner: 'dow_top_decliner' },
        { keyGainer: 'nasdaq_top_gainer', keyDecliner: 'nasdaq_top_decliner' },
        { keyGainer: 'sp_top_gainer', keyDecliner: 'sp_top_decliner' }
    ];

    const getMarketMoverData = () => {
        dispatch(fetchMarketMoverData());
    };

    useEffect(() => {
        getMarketMoverData();
    }, []);

    useEffect(() => {
        const marketMoverData = props.equityDashboardData?.marketMoverData || {};
        if (Object.keys(marketMoverData).length) {
            setResult(marketMoverData);
        }
    }, [props.equityDashboardData]);

    useEffect(() => {
        subResultDataRef.current = resultData;
    }, [resultData]);
    
    useEffect(() => {
        if (resultData && Object.keys(resultData).length > 0) {
            if (socket && !isConnected) {
                socket.emit('connect_to_eodhd', { url: 'wss://ws.eodhistoricaldata.com/ws/us' });

                socket.on('message', (data) => {
                    console.log('message', data);
                });

                socket.on('third_party_message', (data) => {
                    if (data?.message === 'Authorized') {
                        setIsConnected(true);
                    } else if (data?.p) {
                        const keyGainerData = subResultDataRef.current[tabMapping[subSelectedTabRef.current]?.keyGainer] || [];
                        const keyDeclinerData = subResultDataRef.current[tabMapping[subSelectedTabRef.current]?.keyDecliner] || [];
                        const newResultData = [...keyGainerData, ...keyDeclinerData];
                        const res = parseFloat(data.p)?.toFixed(2).toString();

                        const updatedData = newResultData.map((item, index) => {
                            try {
                                if (item.Symbol === data.s) {
                                    const updatedItem = { ...item, 'Stock Price': res };
                                    return updatedItem;
                                }
                            } catch (error) {
                                console.error('Error processing item:', error, item);
                            }
                            return item;
                        });

                        setResult((prevResultData) => {
                            const updatedResult = { ...prevResultData };
                            const keyGainerKey = tabMapping[subSelectedTabRef.current]?.keyGainer;
                            const keyDeclinerKey = tabMapping[subSelectedTabRef.current]?.keyDecliner;

                            if (keyGainerKey) {
                                updatedResult[keyGainerKey] = keyGainerData.map((item) =>
                                    updatedData.find((mainItem) => mainItem.Symbol === item.Symbol)
                                        ? { ...item, ...updatedData.find((mainItem) => mainItem.Symbol === item.Symbol) }
                                        : item
                                );
                            }
                            if (keyDeclinerKey) {
                                updatedResult[keyDeclinerKey] = keyDeclinerData.map((item) =>
                                    updatedData.find((mainItem) => mainItem.Symbol === item.Symbol)
                                        ? { ...item, ...updatedData.find((mainItem) => mainItem.Symbol === item.Symbol) }
                                        : item
                                );
                            }
                            return updatedResult;
                        });
                    }
                });
            }
        }
    }, [socket, isConnected, resultData]);

    useEffect(() => {
        if (resultData && Object.keys(resultData).length > 0) {
            if (socket && isConnected) {
                const keyGainerData = subResultDataRef.current[tabMapping[subSelectedTab]?.keyGainer] || [];
                const keyDeclinerData = subResultDataRef.current[tabMapping[subSelectedTab]?.keyDecliner] || [];
                const newResultData = [...keyGainerData, ...keyDeclinerData];

                const newSymbols = newResultData.map((item) => item.Symbol) || [];
                const symbolsToUnsubscribe = previousSymbols.current.filter((symbol) => !newSymbols.includes(symbol));
                const symbolsToSubscribe = newSymbols.filter((symbol) => !previousSymbols.current.includes(symbol));

                if (symbolsToUnsubscribe.length > 0) {
                    socket.emit('message', { action: 'unsubscribe', symbols: symbolsToUnsubscribe.join(',') });
                }

                if (symbolsToSubscribe.length > 0) {
                    socket.emit('message', { action: 'subscribe', symbols: symbolsToSubscribe.join(',') });
                }

                previousSymbols.current = newSymbols;
            }
        }
    }, [socket, isConnected, resultData, subSelectedTab]);
    
    function compareNumericString(rowA, rowB, id, desc) {
        let a = Number.parseFloat(rowA.values[id]);
        let b = Number.parseFloat(rowB.values[id]);
        if (Number.isNaN(a)) a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        if (Number.isNaN(b)) b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }

    const handleTabChange = (event, newValue) => {
        if (typeof newValue !== 'undefined') setSubSelectedTab(newValue);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Symbol',
                width: 70,
                accessor: 'Symbol',
                disableFilters: true
            },
            {
                Header: 'Company Name',
                width: 100,
                accessor: 'Company Name',
                sticky: true,
                disableFilters: true
            },
            {
                Header: 'Last Price',
                accessor: 'Stock Price',
                sortType: compareNumericString,
                disableFilters: true,
                width: 70
            },
            {
                Header: 'Price Change %',
                accessor: '% Change',
                sortType: compareNumericString,
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: 'Revenue',
                accessor: 'Revenue',
                sortType: compareNumericString,
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            }
        ],
        []
    );

    const CustomTable = ({ data }) => (
        <table style={{ width: '100%', height: 'auto', boxShadow: 'none', borderCollapse: 'unset' }}>
            <thead>
                <tr
                    style={{
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        backgroundColor: 'rgb(231, 245, 255)',
                        color: 'rgb(89, 89, 91)'
                    }}
                >
                    <th style={{ padding: '8px' }}>Symbol</th>
                    <th style={{ padding: '8px' }}>Company Name</th>
                    <th style={{ padding: '8px', textAlign: 'right' }}>Stock Price</th>
                    <th style={{ padding: '8px', textAlign: 'right' }}>Revenue</th>
                    <th style={{ padding: '8px', textAlign: 'right' }}>% Change</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row) => (
                    <tr key={row.Symbol} style={{ backgroundColor: 'inherit' }}>
                        <td style={{ padding: '3px 8px 3px 8px' }}>{row.Symbol}</td>
                        <td style={{ padding: '3px 8px 3px 8px' }}>{row['Company Name']}</td>
                        <td style={{ padding: '3px 8px 3px 8px', textAlign: 'right' }}>{row['Stock Price']}</td>
                        <td style={{ padding: '3px 8px 3px 8px', textAlign: 'right' }}>{row['Revenue']}</td>
                        <td
                            style={{
                                padding: '3px 8px 3px 8px',
                                textAlign: 'right',
                                color: row['% Change'].replace('%', '') < 0 ? 'red' : 'green'
                            }}
                        >
                            {row['% Change']}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const preProccessData = (key) => {
        const processedData = (resultData?.[key] || []).map((item) => ({
            ...item,
            fill: parseFloat(item['% Change']?.replace('%', '')) < 0 ? 'red' : 'green',
        }));
        return processedData
    }

    const renderTablePanel = (dataKeyGainer, dataKeyDecliner) => (
        <>
            {/* <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" className={classes.syncTitle} align="right">
                    Last Trade Time : {moment.utc(lastTradeTime).local().format('L')}
                </Typography>
            </Grid> */}
            <Grid container spacing={3} paddingTop={3} justifyContent="center" paddingBottom={1}>
                <Grid item xs={12} md={2} sm={4} className="widget-grid-item">
                    <SummaryTabPaper elevation={3}>
                        <Typography variant="subtitle1" className="summary-text">
                            Latest Price
                        </Typography>
                        <Typography variant="subtitle1" className="summary-text">
                            {numberWithCommas(resultData?.EquityData?.[subSelectedTab]?.['Latest Price']?.toFixed(3)) || 0}
                        </Typography>
                    </SummaryTabPaper>
                </Grid>
                <Grid item xs={12} md={2} sm={4} className="widget-grid-item">
                    <SummaryTabPaper elevation={3}>
                        <Typography variant="subtitle1" className="summary-text">
                            Daily Price Change %
                        </Typography>
                        <Typography variant="subtitle1" className="summary-text">
                            {numberWithCommas(resultData?.EquityData?.[subSelectedTab]?.['Daily Price Change %']?.toFixed(3)) || 0}
                        </Typography>
                    </SummaryTabPaper>
                </Grid>
                <Grid item xs={12} md={2} sm={4} className="widget-grid-item">
                    <SummaryTabPaper elevation={3}>
                        <Typography variant="subtitle1" className="summary-text">
                            Closing Price
                        </Typography>
                        <Typography variant="subtitle1" className="summary-text">
                            {numberWithCommas(resultData?.EquityData?.[subSelectedTab]?.['Closing Price']?.toFixed(3)) || 0}
                        </Typography>
                    </SummaryTabPaper>
                </Grid>
                <Grid item xs={12} md={2} sm={4} className="widget-grid-item">
                    <SummaryTabPaper elevation={3}>
                        <Typography variant="subtitle1" className="summary-text">
                            5 Day Change %
                        </Typography>
                        <Typography variant="subtitle1" className="summary-text">
                            {numberWithCommas(resultData?.EquityData?.[subSelectedTab]?.['5 Day Change%']?.toFixed(3)) || 0}
                        </Typography>
                    </SummaryTabPaper>
                </Grid>
                <Grid item xs={12} md={2} sm={4} className="widget-grid-item">
                    <SummaryTabPaper elevation={3}>
                        <Typography variant="subtitle1" className="summary-text">
                            1 Year Change %
                        </Typography>
                        <Typography variant="subtitle1" className="summary-text">
                            {numberWithCommas(resultData?.EquityData?.[subSelectedTab]?.['1 Year Change%']?.toFixed(3)) || 0}
                        </Typography>
                    </SummaryTabPaper>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                sx={{
                    '@media (max-width: 767px)': {
                        flexWrap: 'nowrap',
                        overflow: 'auto'
                    }
                }}
            >
                {/* Custom Table */}
                {/* <MarketMoversEquityTable equityData={resultData || []} setEquityData={setResult} />  */}
                                
                                <Grid
                                    item
                                    xs={7}
                                    sm={7}
                                    sx={{
                                        '@media (max-width: 767px)': {
                                            width: '650px',
                                            maxWidth: '650px',
                                            '& table': {
                                                width: '650px !important'
                                            }
                                        }
                                    }}
                                >
                                    <Typography variant="subtitle1" gutterBottom>
                                        Top 10 Gainer's
                                    </Typography>
                                    <CustomTable data={resultData?.[dataKeyGainer] || []} />
                                </Grid>
                                {/* Bar Chart */}
                <Grid item xs={12} sm={5}>
                    <BarChart
                        layout="vertical"
                        width={470}
                        maxBarSize={60}
                        height={387}
                        data={preProccessData(dataKeyGainer) || []}
                        margin={{
                            top: 85,
                            // right: 20,
                            bottom: 26
                            // left: 20
                        }}
                    >
                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                        <XAxis type="number" hide />
                        <YAxis hide tickLine={false} axisLine={false} dataKey={(props) => props['% Change']} type="category" />
                        <Tooltip
                            cursor={false}
                            formatter={(value, name, props) => {
                                if (props?.payload) {
                                    return props.payload['Company Name'];
                                }
                                return null;
                            }}
                        />
                        {/* <Legend /> */}
                        {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                        <Bar dataKey={(props) => Math.abs(props['% Change'].replace('%', ''))} barSize={20} fill={({ payload }) => payload.fill} />
                        {/* <Line dataKey="uv" stroke="#ff7300" /> */}
                    </BarChart>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                sx={{
                    '@media (max-width: 767px)': {
                        flexWrap: 'nowrap',
                        overflow: 'auto'
                    }
                }}
            >
                {/* Custom Table */}
                <Grid
                    item
                    xs={7}
                    sm={7}
                    sx={{
                        '@media (max-width: 767px)': {
                            width: '650px',
                            maxWidth: '650px',
                            '& table': {
                                width: '650px !important'
                            }
                        }
                    }}
                >
                    <Typography variant="subtitle1" gutterBottom>
                        Top 10 Decliner's
                    </Typography>
                    <CustomTable data={resultData?.[dataKeyDecliner] || []} />
                </Grid>

                {/* Bar Chart */}
                <Grid item xs={12} sm={5}>
                    <BarChart
                        layout="vertical"
                        width={470}
                        maxBarSize={60}
                        height={387}
                        data={preProccessData(dataKeyDecliner) || []}
                        margin={{
                            top: 85,
                            // right: 20,
                            bottom: 26
                            // left: 20
                        }}
                    >
                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                        <XAxis type="number" hide />
                        <YAxis hide tickLine={false} axisLine={false} dataKey={(props) => props['% Change']} type="category" />
                        <Tooltip
                            cursor={false}
                            formatter={(value, name, props) => {
                                if (props?.payload) {
                                    return props.payload['Company Name'];
                                }
                                return null;
                            }}
                        />
                        {/* <Legend /> */}
                        {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                        <Bar dataKey={(props) => Math.abs(props['% Change'].replace('%', ''))} barSize={20} fill={({ payload }) => payload.fill} />
                        {/* <Line dataKey="uv" stroke="#ff7300" /> */}
                    </BarChart>
                </Grid>
            </Grid>
        </>
    );

    return (
        <MainBox>
            <Container maxWidth="lg">
                <Tabs value={subSelectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                    <Tab label="Dow" {...a11yProps(0)} />
                    <Tab label="NASDAQ" {...a11yProps(1)} />
                    <Tab label="S&P 500" {...a11yProps(2)} />
                </Tabs>
                {tabMapping.map((tab, index) => (
                    <PortfolioTabPanel key={index} value={subSelectedTab} index={index} dir={theme.direction}>
                        {renderTablePanel(tab.keyGainer, tab.keyDecliner)}
                    </PortfolioTabPanel>
                ))}
            </Container>
        </MainBox>
    );
};

export default MarketMoverTable;
