import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { OutputBox } from 'assets/jss/GeneralStyle';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React from 'react';
import { numberWithCommas } from 'utils/Utils';
import * as tooltip from 'Tooltip.json';

const TaxOutput = ({ data }) => {
    const keys = Object.keys(data);
    const firstKey = keys[0];
    const remainingKeys = keys.slice(1);

    const federalTaxOwed = remainingKeys.slice(0, 4); // First 4 items
    const federalTaxRates = remainingKeys.slice(4, 7); // Next 3 items
    const stateTaxes = remainingKeys.slice(7, 11); // Last 4 items

    const isPercentageKey = (key) =>
        [
            'Top Marginal Tax Rate',
            'Average Tax Rate',
            'Long Term Cap Gains Tax Rate',
            'State Marginal Tax Rate',
            'State Average Tax Rate',
        ].includes(key);

    const formatValue = (key, value) => {
        const appendSign = isPercentageKey(key) ? '%' : '$';
        return isPercentageKey(key) || key === 'Average Tax Rate' || key === 'State Average Tax Rate'
            ? numberWithCommas(value) + (isPercentageKey(key) ? appendSign : '')
            : appendSign + numberWithCommas(value);
    };

    return (
        <Card elevation={2} sx={{ mb: 3.5 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom sx={{ mb: 2, display: 'flex' }}>
                    Summary Output
                </Typography>
                <Box id="outputWrapper" className="widget-full-width">
                    {keys.length === 0 ? (
                        <NoDataFound />
                    ) : (
                        <Grid container spacing={2}>
                            {/* Render the first item */}
                            {firstKey && (
                                <Grid item xs={12} md={4}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            {firstKey}
                                            {tooltip.default?.[firstKey] && (
                                                <CustomTooltip
                                                    tooltipText={tooltip.default[firstKey]}
                                                    customTooltipStyle={{ maxWidth: 450 }}
                                                    label={firstKey}
                                                />
                                            )}
                                        </Typography>
                                        <Typography variant="h6">{formatValue(firstKey, data[firstKey])}</Typography>
                                    </OutputBox>
                                </Grid>
                            )}

                            {/* Render the remaining items grouped by category */}
                            {/* Federal Tax Owed Group */}
                            {federalTaxOwed.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" style={{ width: '100%', textAlignLast: 'center' }}>
                                            Federal Tax Owed
                                        </Typography>
                                    </Grid>
                                    {federalTaxOwed.map((key, i) => (
                                        <Grid item xs={12} md={4} key={i + 'FederalTaxOwed'}>
                                            <OutputBox>
                                                <Typography variant="body1" className="outputLabel" component="p">
                                                    {key}
                                                    {tooltip.default?.[key] && (
                                                        <CustomTooltip
                                                            tooltipText={tooltip.default[key]}
                                                            customTooltipStyle={{ maxWidth: 450 }}
                                                            label={key}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h6">{formatValue(key, data[key])}</Typography>
                                            </OutputBox>
                                        </Grid>
                                    ))}
                                </>
                            )}

                            {/* Federal Tax Rates Group */}
                            {federalTaxRates.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" style={{ width: '100%', textAlignLast: 'center' }}>
                                            Federal Tax Rates
                                        </Typography>
                                    </Grid>
                                    {federalTaxRates.map((key, i) => (
                                        <Grid item xs={12} md={4} key={i + 'FederalTaxRates'}>
                                            <OutputBox>
                                                <Typography variant="body1" className="outputLabel" component="p">
                                                    {key}
                                                    {tooltip.default?.[key] && (
                                                        <CustomTooltip
                                                            tooltipText={tooltip.default[key]}
                                                            customTooltipStyle={{ maxWidth: 450 }}
                                                            label={key}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h6">{formatValue(key, data[key])}</Typography>
                                            </OutputBox>
                                        </Grid>
                                    ))}
                                </>
                            )}

                            {/* State Taxes Group */}
                            {stateTaxes.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" style={{ width: '100%', textAlignLast: 'center' }}>
                                            State Taxes
                                        </Typography>
                                    </Grid>
                                    {stateTaxes.map((key, i) => (
                                        <Grid item xs={12} md={4} key={i + 'StateTaxes'}>
                                            <OutputBox>
                                                <Typography variant="body1" className="outputLabel" component="p">
                                                    {key}
                                                    {tooltip.default?.[key] && (
                                                        <CustomTooltip
                                                            tooltipText={tooltip.default[key]}
                                                            customTooltipStyle={{ maxWidth: 450 }}
                                                            label={key}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h6">{formatValue(key, data[key])}</Typography>
                                            </OutputBox>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </Grid>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default TaxOutput;
