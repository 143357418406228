import React from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Card, CardContent, Grid, InputAdornment, Typography, IconButton, Divider } from '@mui/material';
import { Fragment, useEffect, useState, useSelector } from 'react';
import { useForm, reset } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submitTaxEstiCal, submitTaxEstiCalSuccess, saveTaxEstiCal, fetchTaxEstiCal, fetchSavedTaxEstiCalSuccess } from 'redux/actions/BudgetActions';
import { StateList } from 'States.json';
import { getCookie } from 'utils/Utils';


import RenderField from 'utils/RenderField';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import * as tooltip from 'Tooltip.json';

const handleStateOptions = () => {
    const list = StateList.map((val) => {
        return {
            value: val.abb,
            label: val.name + ` (${val.abb})`
        };
    });
    return list;
};
const minYear = new Date().getFullYear() - 3,
    maxYear = new Date().getFullYear();
const diffYear = maxYear - minYear;
const taxYearOptions = [...Array(diffYear + 1)].map((_, index) => {
    const currVal = minYear + index;
    return { value: currVal, label: currVal };
});
const fieldsDetail = [
    {
        name: 'Filing_Status',
        label: 'Filing Status',
        type: 'select',
        required: true,
        options: [
            { value: 'Married', label: 'Married/Filed Together' },
            { value: 'Single', label: 'Single/Married Filing Separate' },
            { value: 'Hoh', label: 'Head of Household' }
        ]
    },
    {
        name: 'Salary_and_wages',
        label: 'Salary & Wages',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'Int_and_Div',
        label: 'Int & Div',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'Cap_Gains_Inc_LT',
        label: 'Cap Gains Inc LT',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    { name: 'Number_Children', label: 'Number of Children', type: 'slider', min: 0, max: 50 },
    {
        name: 'estimated_federal_tax_payment',
        label: 'Est. Federal Tax Payment',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '1,000'
    },    
    {
        name: 'estimated_state_tax_payment',
        label: 'Est. State Tax Payment',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '1,000'
    },    
    { name: 'Tax_Year', label: 'Tax Year', type: 'select', options: taxYearOptions, required: true },
    { name: 'State', label: 'State', type: 'searchSelect', options: handleStateOptions() }
];

const TaxEstiForm = ({ classes, taxEstiData, defaultoverrideData, savedTaxEstiData, }) => {
    const dispatch = useDispatch();
    const apiToken = getCookie('auth_token');
    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        getValues,
        setValue, 
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            Filing_Status: 'Single',
            Salary_and_wages: 6000.0,
            Int_and_Div: 0.0,
            Cap_Gains_Inc_LT: 20000.0,
            estimated_federal_tax_payment: 0.0,
            estimated_state_tax_payment: 0.0,
            Tax_Year: 2022,
            Number_Children: 0,
            State: { value: 'NY', label: 'New York (NY)' },
                }
    });


    // to reset redux stored value of result when page is changing
    useEffect(() => {
        if (apiToken) dispatch(fetchTaxEstiCal());
        return () => {
            dispatch(fetchSavedTaxEstiCalSuccess({}));
        };
    }, []);

    const handleLoadData = () => {
        const savedData = savedTaxEstiData.data;
        
        // Populate form fields with the saved data
        for (const key in savedData) {
            if (Object.hasOwnProperty.call(savedData, key)) {
                let element = savedData[key];
    
                // Special handling for 'State' field
                if (key === 'State') {
                    const stateValue = element; // The state code, e.g., 'NY'
                    const stateLabel = handleStateOptions().find(state => state.value === stateValue)?.label || stateValue; // Find the full label for the state
                    setValue(key, { value: stateValue, label: stateLabel }); // Set both the value and label for the 'State' field
                } else {
                    setValue(key, element); // For other fields, set the value normally
                }
            }
        }
    
        // Dispatch action to update Redux state and trigger calculations
        dispatch(submitTaxEstiCal(savedData));
        setDisabledLoadBtn(true);
    };

    useEffect(() => {
        const list = handleStateOptions();
        let element = list.filter((val) => val.value === 'NY')[0];
        setValue('State', element); // Ensure NY is selected as the default state
    
        // Reset form dirty state after data is loaded
        reset(savedTaxEstiData.data);
    
        return () => {
            dispatch(submitTaxEstiCalSuccess({}));
        };
    }, []);


    const onSave = async (data) => {
        let objToSend = {
            Filing_Status: data.Filing_Status,
            Salary_and_wages: data.Salary_and_wagesUnFormattedValue || data.Salary_and_wages,
            Int_and_Div: data.Int_and_DivUnFormattedValue || data.Int_and_Div,
            Cap_Gains_Inc_LT: data.Cap_Gains_Inc_LTUnFormattedValue || data.Cap_Gains_Inc_LT,
            Tax_Year: data.Tax_Year,
            estimated_federal_tax_payment: data.estimated_federal_tax_paymentUnFormattedValue || data.estimated_federal_tax_payment,
            estimated_state_tax_payment: data.estimated_state_tax_paymentUnFormattedValue || data.estimated_state_tax_payment,
            Number_Children: data.Number_Children,
            State: data.State?.value || data.State
        };
        

        // Dispatch save action
        await dispatch(saveTaxEstiCal(objToSend));

        // After save completes, fetch the updated data
        await dispatch(fetchTaxEstiCal());  

        // Enable the Load Data button after save and fetch
        setDisabledLoadBtn(false);

    };

    const onKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit(getValues());
        }
    };

    const formatNumber = (value) => {
        if (value === undefined || value === null) return value;
        
        // Ensure the value is a string before applying replace
        let stringValue = value.toString();
    
        // Remove non-numeric characters (such as currency symbols and commas)
        let formattedValue = stringValue.replace(/[^0-9.-]+/g, '');
    
        // Parse the cleaned value into a float
        let parsedValue = parseFloat(formattedValue);
    
        // If the result is NaN (not a number), return 0 or handle appropriately
        return isNaN(parsedValue) ? 0 : parsedValue;
    };
    

    //to submit the form data
    const onSubmit = (data) => {
        const objToSend = {
            Filing_Status: data.Filing_Status,
            Salary_and_wages: formatNumber(data.Salary_and_wages),
            Int_and_Div: formatNumber(data.Int_and_Div),
            Cap_Gains_Inc_LT: formatNumber(data.Cap_Gains_Inc_LT),
            Tax_Year: data.Tax_Year,
            estimated_federal_tax_payment: formatNumber(data.estimated_federal_tax_payment),
            estimated_state_tax_payment: formatNumber(data.estimated_state_tax_payment),
            Number_Children: data.Number_Children,
            State: data.State?.value || data.State
        };

        dispatch(submitTaxEstiCal(objToSend));
    };

    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
        }
        if (isDirty && Object.keys(taxEstiData).length > 0) {
            dispatch(submitTaxEstiCalSuccess({}));
        }
    };

    return (
        <Card className={`${classes.widgetCalCard}`} elevation={3}>
            <CardContent>
            {savedTaxEstiData?.data && Object.keys(savedTaxEstiData?.data).length > 3 ? (
                    <Box color="text.primary">
                        <Typography variant="body2" component="p">
                            We have your saved data. Click button to load it.
                            <IconButton
                                color="primary"
                                aria-label="load data"
                                component="label"
                                disabled={disabledLoadBtn}
                                onClick={handleLoadData}
                            >
                                <CachedIcon color="white" />
                            </IconButton>
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                    </Box>
                ) : (
                    ''
                )}
                <form onKeyDown={onKeyPressHandler} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        {fieldsDetail.map((val, i) => {
                            if (val.type === 'slider') {
                                return (
                                    <Fragment key={i + 'lifeInsForm'}>
                                        <Grid item xs={12} md={5} lg={5} className={classes.insSliderLabel}>
                                            <Typography gutterBottom sx={{ ml: 1 }}>
                                                {val.label}
                                                {val.label
                                                    ? tooltip.default?.[val.label] && (
                                                          <InputAdornment position="end" sx={{ margin: '20px 0' }}>
                                                              <CustomTooltip tooltipText={tooltip.default?.[val.label]} label={val.label} />
                                                          </InputAdornment>
                                                      )
                                                    : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7} lg={7} className={classes.insSliderField}>
                                            <RenderField
                                                type="slider"
                                                control={control}
                                                name={val.name}
                                                id={val.name}
                                                max={val.max}
                                                min={val.min}
                                                disabled={val.disabled}
                                                valueLabelDisplay="on"
                                                handleOnChange={handleFormInputChange}
                                            />
                                        </Grid>
                                    </Fragment>
                                );
                            }
                            return (
                                <Grid item xs={12} sm={6} key={i + 'taxestiform'}>
                                    <RenderField
                                        name={val.name}
                                        control={control}
                                        label={val.label}
                                        id={val.name + '_taxestiform'}
                                        type={val.type}
                                        thousandSeparator={val.commaSep}
                                        customPatternName={val.custompattern}
                                        required={val.required || false}
                                        placeholder={val.placeholder}
                                        setValue={setValue}
                                        disabled={val.disabled}
                                        handleOnChange={handleFormInputChange}
                                        renderOption={(props, option) => {
                                            return (
                                                <Box component="li" sx={{ m: 1 }} {...props}>
                                                    {option.label}
                                                </Box>
                                            );
                                        }}
                                        {...val}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CalCardActions>
                        <Grid container style={{ width: '100%', textAlign: 'center' }}>
                            <Grid item xs={12} md={6}>
                                <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                    Calculate
                                </CustomButton>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <CustomButton
                                type="button"
                                ps={'9px 30px'}
                                variant="outlined"
                                onClick={() => {
                                    onSave(getValues());
                                }}
                                disabled={isSubmitting}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                        </Grid>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default TaxEstiForm;
