import { useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { getCookie } from './Utils';

const useSocket = (url) => {
    const socket = useRef(null);
    const token = getCookie('auth_token');
    useEffect(() => {
        if (!token) {
            console.error('No token found');
            return;
        }
        const options = {
            extraHeaders: {
                'Authorization': `Bearer ${token}`,
            },
        };
        console.log('Socket connection options:', options);
        socket.current = io(url, options);
        socket.current.on('connect', () => {
            console.log('Connected to backend socket');
        });
        socket.current.on('disconnect', () => {
            console.log('Disconnected from backend socket');
        });
        return () => {
            if (socket.current) socket.current.disconnect();
        };
    }, [url, token]);
    return socket.current;
};
export default useSocket;