import React, { useEffect, useRef, useState } from 'react';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import { useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import { Container, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EquityDashTable from './EquityDashTable.jsx';
import EquityDashDesc from './EquityDashDesc.jsx';
import SwipeableViews from 'react-swipeable-views';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel.js';
import { a11yProps } from 'utils/Utils.js';
import WatchList from './WatchList.jsx';
import { AddUserOptionBox } from '../../assets/jss/PortfolioStyle';
import GetAQuote from './GetAQuote';
import Meta from 'components/meta.jsx';
import MarketMoverTable from './MarketMoverTable.jsx';

export default function EquityDashBoard(props) {
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();
    const theme = useTheme();
    const equityDashboardData = useSelector((state) => state.equityDashboard);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'eqDash';
        }
    }, []);

    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Dashboards'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Equity Dashboard
        </Typography>
    ];
    const handleTabChange = (event, newValue) => typeof newValue !== 'undefined' && setSelectedTab(newValue);

    const handleSwipe = (event) => {
        const delta = Math.sign(event.deltaX);
        const newIndex = selectedTab - delta;
        if (newIndex >= 0 && newIndex < 2) {
            setSelectedTab(newIndex);
        }
    };

    return (
        <>
            <Meta
                title="Equity Dashboard | TPE Hub"
                description="Access and manage all your equity investments in one place with our Equity Dashboard. Monitor stock performance, review portfolio details, and make informed investment decisions."
                page="/equity-dashboard"
            />
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="Equity Dashboard" />
            <MainBox>
                <Container maxWidth="lg">
                    {equityDashboardData.loader ? <Loader /> : ''}
                    <Tabs
                        className="topblock-tabs"
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="scrollable"
                        allowScrollButtonsMobile={true}
                        aria-label="equity summary tabs"
                    >
                        <Tab label="Dashboard" {...a11yProps(0)} />
                        <Tab label="Market Movers" {...a11yProps(1)} />
                        <Tab label="Watchlists" {...a11yProps(2)} />
                        <AddUserOptionBox>
                            <GetAQuote equityDashboardData={equityDashboardData} />
                        </AddUserOptionBox>
                    </Tabs>
                    <div style={{ overflow: 'hidden', position: 'relative' }} onTouchStart={(event) => event.preventDefault()}>
                        <div
                            style={{
                                display: 'flex',
                                transition: 'transform 0.3s ease',
                                transform: `translateX(-${selectedTab * 100}%)`
                            }}
                            onTouchMove={handleSwipe}
                        >
                            <div style={{ flex: 'none', width: '100%' }}>
                                <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                                    <EquityDashTable equityDashboardData={equityDashboardData} />
                                </PortfolioTabPanel>
                            </div>
                            <div style={{ flex: 'none', width: '100%' }}>
                                <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                                    <MarketMoverTable equityDashboardData={equityDashboardData} />
                                </PortfolioTabPanel>
                            </div>
                            <div style={{ flex: 'none', width: '100%' }}>
                                <PortfolioTabPanel value={selectedTab} index={2} dir={theme.direction}>
                                    <WatchList equityDashboardData={equityDashboardData} />
                                </PortfolioTabPanel>
                            </div>
                        </div>
                    </div>
                    <EquityDashDesc />
                </Container>
            </MainBox>
        </>
    );
}
