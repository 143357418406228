import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchEquityDashboardData } from "redux/actions/EquityDashboardActions";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import { numberWithCommas } from "utils/Utils";
import { limitWords } from "utils/Utils";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import WatchListChart from "./WatchListChart";
import { fetchWatchListChart } from "redux/actions/EquityDashboardActions";
import { CustomButton } from "assets/jss/ButtonStyle";
import useSocket from "utils/SocketIo";
import Globals from "Globals";

const EquityDashTable = (props) => {
  const dispatch = useDispatch();
  const [resultData, setResult] = useState([]);
  const [colorScaleArr, setColorScaleArr] = useState({});
  const [enableChart, setEnableChart] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const previousSymbols = useRef([]);
  const socket = useSocket(`${Globals.SIO_URL}`); // Replace with backend URL

  const getEquityDashboardData = () => {
    dispatch(fetchEquityDashboardData());
  };

  const handleSymbolClick = (data) => {
    setEnableChart(data);
    dispatch(fetchWatchListChart(data.symbol));
  };

  const handleRangeSelection = (range) => {
    dispatch(fetchWatchListChart(enableChart.symbol, range));
  };

  useEffect(() => {
    getEquityDashboardData();
  }, []);

  useEffect(() => {
    if (Object.keys(props.equityDashboardData.equityDashboardDetail).length) {
      const resultData = props.equityDashboardData.equityDashboardDetail.data;
      setColorScaleArr(props.equityDashboardData.arrForColorScale);
      console.log("resultData", resultData);
      setResult(resultData);
    }
  }, [props.equityDashboardData]);

  useEffect(() => {
    if (socket) {
      if (!isConnected) {
        socket.emit('connect_to_eodhd', { url: 'wss://ws.eodhistoricaldata.com/ws/us' });
      }

      socket.on('message', (data) => {
        console.log('message', data, "-----------------");
      });

      socket.on('third_party_message', (data) => {
        // console.log('message from eodhd', data, "-----------------");
        if (data?.message === "Authorized") {
          // console.log('Authorized');
          setIsConnected(true);
        } else if (data?.p) {
          // console.log('data received', data);
          let newData = resultData.map((item) => {
            if (item.symbol === data.s) {
              let updated_item = {
                ...item,
                "Latest Price": parseFloat(data.p),
                "Daily Price Change %": item["Latest Price"] ? ((parseFloat(data.p) - item["Closing Price"]) / item["Closing Price"]) * 100 : 0,
              };
              return updated_item;
            }
            return item;
          });
          setResult(newData);
          // console.log('resultData', resultData);
        }
      });
    }

    // return () => {
    //   if (socket) {
    //     socket.emit('message', { action: 'unsubscribe', symbols: previousSymbols.current.join(',') });
    //     socket.emit('disconnect_from_eodhd');
    //     socket.disconnect();
    //     setIsConnected(false);
    //   }
    // };
  }, [socket, resultData]);

  useEffect(() => {
    if (socket && isConnected) {
      const newSymbols = resultData.map((item) => item.symbol);
      const symbolsToUnsubscribe = previousSymbols.current.filter(symbol => !newSymbols.includes(symbol));
      const symbolsToSubscribe = newSymbols.filter(symbol => !previousSymbols.current.includes(symbol));

      if (symbolsToUnsubscribe.length > 0) {
        socket.emit('message', { action: 'unsubscribe', symbols: symbolsToUnsubscribe.join(',') });
      }

      if (symbolsToSubscribe.length > 0) {
        socket.emit('message', { action: 'subscribe', symbols: symbolsToSubscribe.join(',') });
      }

      previousSymbols.current = newSymbols;
    }
  }, [resultData, socket, isConnected]);

  function compareNumericString(rowA, rowB, id, desc) {
    let a = Number.parseFloat(rowA.values[id]);
    let b = Number.parseFloat(rowB.values[id]);
    if (Number.isNaN(a)) {  // Blanks and non-numeric strings to bottom
      a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (Number.isNaN(b)) {
      b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
    }
    if (a > b) return 1;
    if (a < b) return -1;
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: "Symbol",
        accessor: "symbol",
        disableFilters: true,
        sticky: true,
        width: 100,
        Cell: ({ row, value }) => {
          return <CustomButton variant="text" onClick={() => handleSymbolClick(row.original)}>{value}</CustomButton>;
        }
      },
      {
        Header: "Company Name",
        width: 100,
        accessor: "Company Name",
        disableFilters: true,
      },
      {
        Header: "Latest Price",
        width: 70,
        accessor: "Latest Price",
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        sortType: compareNumericString,
        disableFilters: true,
      },
      {
        Header: "Daily Price Change %",
        accessor: "Daily Price Change %",
        sortType: compareNumericString,
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        colorScaleArr: colorScaleArr["Daily Price Change %"],
        isMiddleColorYellow: true,
        disableFilters: true,
        width: 70,
      },
      {
        Header: "Closing Price",
        accessor: "Closing Price",
        sortType: compareNumericString,
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        disableFilters: true,
        width: 70,
      },
      {
        Header: "5 Day Change %",
        accessor: "5 Day Change%",
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        sortType: compareNumericString,
        colorScaleArr: colorScaleArr["5 Day Change%"],
        isMiddleColorYellow: true,
        disableFilters: true,
        width: 70,
      },
      {
        Header: "1 Year Change %",
        accessor: "1 Year Change%",
        Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
        sortType: compareNumericString,
        colorScaleArr: colorScaleArr["1 Year Change%"],
        isMiddleColorYellow: true,
        disableFilters: true,
        width: 70,
      },
      {
        Header: "Latest Headline",
        accessor: "Latest Headline",
        Cell: ({ value, row, cell }) => {
          return value ? limitWords(value, 5) !== value ?
            <CustomTooltip tooltipText={value} textTooltip={false} textLimit={5} align="left" />
            : value : '-'
        },
        disableFilters: true,
      },
    ];
  }, [colorScaleArr]);

  return (
    <>
      {Object.keys(enableChart).length > 0 ? (
        <WatchListChart
          chartdetails={enableChart}
          backButtonClicked={() => setEnableChart({})}
          rangeSelectionClicked={(val) => handleRangeSelection(val)}
          equityResultData={resultData}
        />
      ) : (
        <EnhancedTable
          enableSearch={false}
          handleLoadData={getEquityDashboardData}
          title={'Equities'}
          columns={columns}
          data={resultData}
          enableSort={true}
          enablePagination={false}
          loader={props?.equityDashboardData.loader}
        />
      )}
    </>
  );
}

export default EquityDashTable;
